import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom"; 
import { toast } from "react-toastify";

const ProtectedRoute = (props) => {
  const Comp = props.Component;
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = Cookies.get("accessToken");
    if (accessToken === undefined) { 
      toast.error("Please Sign In")
      return navigate("/");
    }
  },);

  return (
    <div>
      <Comp />
    </div>
  );
};

export default ProtectedRoute;
