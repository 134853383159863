import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLogin: false,
  isSignup: false,
  iskey: "home",
  showCredit: false,
  showModal: true,
  language: "en",
  isShowNavbar: true,
  isShowForgotPasswordModal: false,
  isShowForgotUsernameModal: false,
  isShowDeactivateAlert: false,
  isShowMoreDetails: false,
  showSingupPopup: false,
  showGameCreditPopUp: false,
  deactivateToggle: false,
  applyOperatorModal: false,
};
const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    isLoginFun: (state, action) => {
      state.isLogin = action.payload;
    },
    isSignupFun: (state, action) => {
      state.isSignup = action.payload;
    },
    modalKey: (state, action) => {
      state.iskey = action.payload;
    },
    showGameCredit: (state, action) => {
      state.showCredit = action.payload;
    },
    setModalData: (state, action) => {
      state.showModal = action.payload;
    },
    changeLaguage: (state, action) => {
      state.language = action.payload;
    },
    toggleNavbar: (state, action) => {
      state.isShowNavbar = action.payload;
    },
    showForgotPasswordModal: (state, action) => {
      state.isShowForgotPasswordModal = action.payload;
    },
    showForgotUsernameModal: (state, action) => {
      state.isShowForgotUsernameModal = action.payload;
    },
    showDeactivateAlert: (state, action) => {
      state.isShowDeactivateAlert = action.payload;
    },
    showMoreDetailsLastActivity: (state, action) => {
      state.isShowMoreDetails = action.payload;
    },
    setShowSingupPop: (state, action) => {
      state.showSingupPopup = action.payload;
    },
    setShowGameCreditModal: (state, action) => {
      state.showGameCreditPopUp = action.payload;
    },
    setDeactivateToggle: (state, action) => {
      state.deactivateToggle = !action.payload;
    },
    setApplyOperatorModal: (state, action) => {
      state.applyOperatorModal = action.payload;
    },
  },
});

export const {
  setApplyOperatorModal,
  setDeactivateToggle,
  setShowGameCreditModal,
  isLoginFun,
  setShowSingupPop,
  isSignupFun,
  modalKey,
  showGameCredit,
  setModalData,
  changeLaguage,
  toggleNavbar,
  showForgotPasswordModal,
  showForgotUsernameModal,
  showDeactivateAlert,
  showMoreDetailsLastActivity,
} = counterSlice.actions;

export default counterSlice.reducer;
