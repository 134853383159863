import React, { useState } from "react";
import { Modal } from "react-bootstrap-v5";

const TransferToFriendPopup = ({ transerToFriend, setTranserToFriend }) => {
  const initialValues = {
    username: "",
    amount: "",
    password: "",
    checkboxAgree: false,
  };

  const [details, setDetails] = useState(initialValues);
  const [formError, setFormError] = useState({ initialValues });
  const [showPassword, setShowPassword] = useState(false);

  const handleClose = () => setTranserToFriend(false);
  const onBlurHandler = (event) => {
    const fieldName = event.target.name;
    const fieldValue = details[fieldName];
    let err = { ...formError };
    if (fieldValue === "") {
      err[fieldName] = "Required!";
    } else if (fieldName === "checkboxAgree") {
      if (fieldValue === false) {
        err[fieldName] = "Required!";
      } else {
        delete err[fieldName];
      }
    } else {
      delete err[fieldName];
    }
    setFormError({ ...err });
  };

  const validateForm = () => {
    let err = {};
    if (details.username === "") {
      err.username = "Required!";
    }
    if (details.amount === "") {
      err.amount = "Required!";
    }
    if (details.password === "") {
      err.password = "Required!";
    }
    if (details.checkboxAgree === false) {
      err.checkboxAgree = "Required!";
    }
    setFormError({ ...err });
    return Object.keys(err).length < 1;
  };

  const handleChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    let err = { ...formError };
    delete err[fieldName];
    setFormError({ ...err });

    if (fieldName === "amount" && isNaN(e.target.value)) {
      return;
    } else if (fieldName === "password") {
      if (fieldValue.length > 16) {
        return;
      }
    }

    setDetails((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    validateForm();
  };
  return (
    <>
      <Modal
        show={transerToFriend}
        onHide={handleClose}
        size="xl"
        className="login-modal modalClosebtn"
      >
        <Modal.Header closeButton className="btn-close-white"></Modal.Header>
        <Modal.Body className="customFlexClass ">
          <div className="row m-0">
            <div className="col-10 col-md-6 mx-auto">
              <div className="row m-0">
                <div className="col-md-12 col-lg-12 d-flex justify-content-start align-items-center mx-auto">
                  <div className="heading-text mx-auto">
                    <div className="d-flex justify-content-center align-items-center mb-3">
                      <div className="titleWithIcon">
                        <i className="fa-solid  fa-share transactionIcon"></i>
                      </div>
                      <p className=" section-heading customPopupsHeading transactionTitle">
                        Transfer To a Friend
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <p className="redeemMsgInfo text-white">
                You can transfer your main Wallet balance to a friend connected
                with the same Operator
              </p>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12 mt-2 mb-3">
                    <div className="user-input ">
                      <label
                        htmlFor="inputState"
                        className="form-label text-white mb-2"
                      >
                        Username*
                      </label>
                      <input
                        className="form-control user-input"
                        type="text"
                        placeholder="Enter Your Username"
                        aria-label="first-name"
                        name="username"
                        maxLength={20}
                        value={details.username}
                        onChange={handleChange}
                        onBlur={onBlurHandler}
                      />
                      <span className="errorMsg">{formError.username}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="user-input">
                      <label
                        htmlFor="inputState"
                        className="form-label text-white mb-2 customPopUpLabel"
                      >
                        Amount*
                      </label>
                      <div className="input-group ">
                        <span
                          className="input-group-text dollarSymbol"
                          id="basic-addon1"
                        >
                          $
                        </span>
                        <input
                          type="text"
                          className="form-control  user-input"
                          placeholder="Enter an amount to redeem"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          name="amount"
                          value={details.amount}
                          maxLength={4}
                          onChange={handleChange}
                          onBlur={onBlurHandler}
                        />
                      </div>
                      <span className="errorMsg">{formError.amount}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="user-input">
                      <label
                        htmlFor="inputState"
                        className="form-label text-white mb-2 customPopUpLabel"
                      >
                        Password*
                      </label>
                      <input
                        className="form-control user-input"
                        type={`${showPassword === true ? "text" : "password"}`}
                        placeholder="Enter Your Password"
                        aria-label="first-name"
                        name="password"
                        autoComplete="off"
                        value={details.password}
                        onChange={handleChange}
                        onBlur={onBlurHandler}
                      />
                      <i
                        className={`${
                          showPassword ? "bi bi-eye" : "bi bi-eye-slash"
                        }  toggle-password`}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                      <span className="errorMsg">{formError.password}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-2 mb-3">
                    <div className="form-check">
                      <div className="col">
                        <div className="rememberme">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="checkbox"
                            name="checkboxAgree"
                            value={details.checkboxAgree}
                            onChange={handleChange}
                            onBlur={onBlurHandler}
                          />
                          <label
                            className="form-check-label label-text"
                            htmlFor="checkbox"
                          >
                            I agree to{" "}
                            <span className="walletBalance">
                              Terms & Conditions
                            </span>
                            .
                          </label>
                        </div>
                      </div>
                    </div>
                    <span className="errorMsg">{formError.checkboxAgree}</span>
                  </div>
                </div>
                <div className="d-grid gap-2 signin-btn mt-2">
                  <button className="btn btn-sign" type="submit">
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransferToFriendPopup;
