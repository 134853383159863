import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

const API_URL =
`${process.env.REACT_APP_BASE_URL}/casino/all-games?pageNo=1&limit=100`;

// Get Request

export const getGameData = createAsyncThunk(
  "getData",
  async (data, { rejectWithValue }) => {
    const { currentPage, postPerPage } = data;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/casino/all-games?pageNo=${currentPage}&limit=${postPerPage}`
      );
      return response?.data.data.categoryGames.rows
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Launch Game Request

export const launchGame = createAsyncThunk(
  "launchGame",
  async (requestData, { rejectWithValue }) => {
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "post",
      url:  `${process.env.REACT_APP_BASE_URL}/casino/launch-game`,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: requestData?.data,
    };


    try {
      const response = await axios(config);
      window.open(response.data.data.data.URL, "_blank");

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//POST Request

export const postData = createAsyncThunk(
  "postData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(API_URL, data);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const trendingGameSlice = createSlice({
  name: "trendingGame",
  initialState: {
    gameList: [],
    loading: false,
    error: null,
    launchGameUrl: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGameData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGameData.fulfilled, (state, action) => {
        state.loading = false;
        state.gameList = action.payload;
      })
      .addCase(getGameData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(launchGame.pending, (state) => {
        state.loading = true;
      })
      .addCase(launchGame.fulfilled, (state, action) => {
        state.loading = false;
        state.launchGameUrl = action.payload;
      })
      .addCase(launchGame.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default trendingGameSlice.reducer;
