import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify"; 


export const getWinnerListData  = createAsyncThunk("getWinnerListData", async () => {
    const API_URL = `${process.env.REACT_APP_BASE_URL}/casino/recent-winings`;
    
    const config = {
      method: "get",
      url: API_URL,
      headers: { 
        type: "text",
      },
      data: {},
    };
    try {
      const response = await axios(config);
       return response?.data?.data?.recentWinings
    } catch (error) {
      if(error?.response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
        Cookies.remove("accessToken") 
        window.location.href="/"
          
      }else{ 
        toast?.error(error?.response?.data?.errors[0]?.description) 
      }
      throw error;
    }
  });


 

const lastWinnerListSlice = createSlice({
    name: "lastWinners",
    initialState: { 
        isLoading:false,
        lastWinners:[],
        error:null
    },
    extraReducers: (builder) => {
      builder
        .addCase(getWinnerListData.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getWinnerListData.fulfilled, (state, action) => {
          state.isLoading = false;
          state.lastWinners = action.payload;
        })
        .addCase(getWinnerListData.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
        })        
    },
  });
  
  export default lastWinnerListSlice.reducer;
  