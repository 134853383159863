import {useState } from "react";
import { getUserData } from "../../../redux/signUpSlice";
import { useSelector, useDispatch } from "react-redux";
import { apiResponseData } from "../../../redux/signUpSlice";
import axios from "axios";
import swal from "sweetalert";

const ThirdStep = ({ seThowSecond, setShowthird, generateOtp }) => {
  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const [passowrdData, setPasswordData] = useState(initialValues);
  const [formError, setFormError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch(); 
  const onBlurHandler = (event) => {
    const fieldName = event.target.name;
    const fieldValue = passowrdData[fieldName];
    let err = { ...formError };

    if (fieldName === "password" || fieldName === "confirmPassword") {
      if (fieldValue.length < 8) {
        err[fieldName] =
          "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 special character, and be at least 8 characters long.";
      }
    }
    if (fieldValue === "") {
      err[fieldName] = "Required!";
    } else {
      delete err[fieldName];
    }
    setFormError({ ...err });
  };

  const handleOnchange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    let err = { ...formError };
    delete err[fieldName];

    if (fieldName === "password" || fieldName === "confirmPassword") {
      if (fieldValue.length > 16) {
        return;
      }
    }

    setPasswordData((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    }));
    setFormError({ ...err });
  };

  const validateForm = () => {
    let err = {};
    if (passowrdData.password === "") {
      err.password = "Required!";
    }
    if (passowrdData.confirmPassword === "") {
      err.confirmPassword = "Required!";
    } else {
      if (passowrdData.password !== passowrdData.confirmPassword) {
        err.confirmPassword = "Passwords do not match!";
      } else if (!isValidPassword(passowrdData.password)) {
        err.confirmPassword =
          "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 special character, and be at least 8 characters long.";
      }
    }
    setFormError({ ...err });
    return Object.keys(err).length < 1;
  };

  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
    return passwordRegex.test(password);
  };

  const handleSetPassword = () => {
    dispatch(getUserData(passowrdData));
  };

  const { userDetails } = useSelector((state) => state.singUpData);  let { showModal } = useSelector((state) => state.counter);

  const requestedData = () => {
    let phoneWithCode = userDetails[0].number;
    let totalLength = phoneWithCode.length;

    let countryCode = phoneWithCode.slice(0, totalLength - 10);
    let countrCodeLength = countryCode.length;
    let phoneNumber = phoneWithCode.slice(
      countrCodeLength,
      countrCodeLength + 10
    );

    const apiObj = {
      username: userDetails[0].username,
      password: passowrdData.password,
      phoneCode: Number(countryCode),
      phone: phoneNumber,
    };
  };

  const handleAPICall = async () => {
    let phoneWithCode = userDetails[0].number;
    let totalLength = phoneWithCode.length;

    let countryCode = phoneWithCode.slice(0, totalLength - 10);
    let countrCodeLength = countryCode.length;
    let phoneNumber = phoneWithCode.slice(
      countrCodeLength,
      countrCodeLength + 10
    );

    const apiObj = {
      username: userDetails[0].username,
      password: passowrdData.password,
      phoneCode: Number(countryCode),
      phone: phoneNumber,
    };

    const API_URL =  `${process.env.REACT_APP_BASE_URL}/user/signup`;
    try {
      const response = await axios.post(API_URL, apiObj);

      if (response.status === 200) {
        setShowthird(true);
        seThowSecond(false);
        generateOtp();
        dispatch(apiResponseData(response.data));

        swal({
          title: "Registered Successfully!",
          icon: "success",
          type: "success",
          showCancelButton: false,
          confirmButtonClass: "",
          confirmButtonText: "Ok",
          closeOnConfirm: false,
        }).then((willDelete) => {
          if (willDelete) {
            
          }
        });
      }
    } catch (error) {

      let message = error?.response?.data?.errors[0]?.description?.split("_");
      let updatedMessage = message?.join(" ");
      swal({
        title: updatedMessage,
        icon: "warning",
        type: "warning",
        showCancelButton: false,
        confirmButtonClass: "",
        confirmButtonText: "Ok",
        closeOnConfirm: false,
      }).then((willDelete) => {
        if (willDelete) {
          window.location.reload();
        }
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      handleSetPassword();
      requestedData();
      handleAPICall();
    }
  };

  const handleShowpassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="col-12 col-md-6 col-lg-8 mx-auto">
      <div className="signupScreen">
        <div>
          <div className="welcome mt-3">
            <p className="welcome-text">Set up your password</p>
          </div>
          <div className="sign-text">
            <p className="text-white confirm-text text-center">
              Please enter and confirm your password to complete the sign-up
              process.
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="password-input mt-2">
              <input
                className="form-control user-input mt-3"
                type={`${showPassword === true ? "text" : "password"}`}
                placeholder="Password"
                aria-label="password"
                name="password"
                onBlur={onBlurHandler}
                onChange={handleOnchange}
                value={passowrdData.password}
              />
              <i
                className={`${
                  showPassword ? "bi bi-eye" : "bi bi-eye-slash"
                }  toggle-password`}
                onClick={handleShowpassword}
              />
              <span className="errorMsg">{formError.password}</span>
            </div>
            <div className="password-input mt-2">
              <input
                className="form-control user-input mt-3"
                type={`${showConfirmPassword === true ? "text" : "password"}`}
                placeholder="Confirm password"
                aria-label="password"
                name="confirmPassword"
                onBlur={onBlurHandler}
                onChange={handleOnchange}
                value={passowrdData.confirmPassword}
              />
              <i
                className={`${
                  showConfirmPassword ? "bi bi-eye" : "bi bi-eye-slash"
                } toggle-password`}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
              <span className="errorMsg" style={{ whiteSpace: "pre-wrap" }}>
                {formError.confirmPassword}
              </span>
            </div>
            <div className="d-grid gap-2 signin-btn">
              <button className="btn btn-sign" type="submit">
                Join Us Now
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ThirdStep;
