import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const updatePhoneNumber = createAsyncThunk(
  "editProfile",
  async (requestData, { rejectWithValue }) => {
    const API_URL =  `${process.env.REACT_APP_BASE_URL}/user/update-phone`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "post",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: requestData,
    };
    try {
      const response = await axios(config);
      return response?.data;
    } catch (error) {
      
      return rejectWithValue(error);
    }
  }
);

export const verifyPhoneCode = createAsyncThunk(
  "verifyCode",
  async (requestData, { rejectWithValue }) => {
    const API_URL =  `${process.env.REACT_APP_BASE_URL}/user/verify-phone-code`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "post",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: requestData,
    };
    try {
      const response = await axios(config);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const updatePhonenumberSlice = createSlice({
  name: "updatePhoneNumber",
  initialState: {
    updateNumberResponse: [],
    verifyCodeResponse: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePhoneNumber.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePhoneNumber.fulfilled, (state, action) => {
        state.loading = false;
        state.updateNumberResponse = action.payload;
      })
      .addCase(updatePhoneNumber.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(verifyPhoneCode.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyPhoneCode.fulfilled, (state, action) => {
        state.loading = false;
        state.verifyCodeResponse = action.payload;
      })
      .addCase(verifyPhoneCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default updatePhonenumberSlice.reducer;
