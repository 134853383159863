import React, { lazy, Suspense } from 'react';
import "./assets/bootstrap/bootstrap.min.css";
import "./assets/css/master.css"; 
import "./assets/css/responsive.css";
import "react-bootstrap-v5";
import { Routes, Route, HashRouter, BrowserRouter } from "react-router-dom"; 
import Navbar from "./components/Navbar/Navbar"; 
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute"; 
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import { io } from "socket.io-client"; 
import ScrollToTop from "./components/ScrollToTop/ScrollToTop ";   
import { useSelector } from "react-redux"; 
import BonusTermsConditions from './pages/BonusTermsConditions';   


const HomePage = lazy(() => import('./pages/HomePage'));
const Operators = lazy(() => import('./pages/Operators'));
const Notifications = lazy(() => import('./pages/Notifications'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const Faq = lazy(() => import('./pages/Faq'));
const EditProfile = lazy(() => import('./components/UserProfile/EditProfile'));
const MyOperators = lazy(() => import('./components/MyOperator/MyOperators'));
const Deativate = lazy(() => import('./components/UserProfile/Deativate'));
const ChangePassword = lazy(() => import('./components/UserProfile/ChangePassword'));
const LastActivity = lazy(() => import('./components/MyOperator/LastActivity'));
const ChangeNumber = lazy(() => import('./components/UserProfile/ChangeNumber'));
const Redeem = lazy(() => import('./components/MyOperator/Redeem'));
const TransactionDataTable = lazy(() => import('./components/MyOperator/TransactionDataTable'));
const Cart = lazy(() => import('./components/MyOperator/Cart'));
const Bonus = lazy(() => import('./pages/Bonus'));
const NotFound = lazy(() => import('./pages/NotFound'));
const TermsConditions = lazy(() => import('./pages/TermsConditions'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy')); 
const TransferToFriend = lazy(() => import('./components/MyOperator/TransferToFriend'));


 

function App() {
  const { isShowNavbar } = useSelector((state) => state.counter);
  
const accessToken = Cookies.get("accessToken")
  const disableInspect = process.env.REACT_APP_BASE_URL;

  if (!disableInspect.includes("dev-api")) {
    document.addEventListener("keydown", (event) => {
      if (
        event.keyCode === 123 ||
        (event.ctrlKey && event.shiftKey && event.keyCode === 73)
      ) {
        event.preventDefault();
      }
    });

    document.addEventListener("contextmenu", (event) => event.preventDefault());
  }

  return (
    <div className="App">
      <ToastContainer  style={{ zIndex: "9999" }}/>
      <BrowserRouter>
        <Navbar />
        <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<HomePage />} />

          <Route
            path="/operators"
            element={<ProtectedRoute Component={Operators} />}
          />
          <Route
            path="/notifications"
            element={<ProtectedRoute Component={Notifications} />}
          />
          <Route
            path="/editProfile"
            element={<ProtectedRoute Component={EditProfile} />}
          />
          <Route
            path="/changeNumber"
            element={<ProtectedRoute Component={ChangeNumber} />}
          />
          <Route
            path="/myOperators"
            element={<ProtectedRoute Component={MyOperators} />}
          />
          <Route
            path="/deactivate"
            element={<ProtectedRoute Component={Deativate} />}
          />
          <Route
            path="/changePassword"
            element={<ProtectedRoute Component={ChangePassword} />}
          />
          <Route
            path="/lastActivity"
            element={<ProtectedRoute Component={LastActivity} />}
          />
          <Route
            path="/myOperators"
            element={<ProtectedRoute Component={MyOperators} />}
          />
          <Route
            path="/transactions"
            element={<ProtectedRoute Component={TransactionDataTable} />}
          />
          <Route
            path="/redeem"
            element={<ProtectedRoute Component={Redeem} />}
          />
          <Route path="/cart" element={<ProtectedRoute Component={Cart} />} />
          <Route
            path="/contactus"
            element={<ProtectedRoute Component={ContactUs} />}
          />
          <Route
          path="/bonus"
          element={<ProtectedRoute Component={Bonus} />}
        /> 
        <Route
        path="/bonust&c"
        element={<ProtectedRoute Component={BonusTermsConditions} />}
      /> 
        <Route
        path="/
        &c"
        element={<ProtectedRoute Component={BonusTermsConditions} />}
      /> 
          {/*<Route
          path="/transferToFriend"
          element={<ProtectedRoute Component={TransferToFriend} />}
        />*/}
          <Route path="/faq" element={<Faq />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/t&c" element={<TermsConditions />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <div>
        <ScrollToTop/>
       {/*accessToken && isShowNavbar && <Chatbot/>*/}
        </div>
        </Suspense>
       
       
      </BrowserRouter>
    </div>
  );
}

export default App;
