import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";


export const getUserDetails = createAsyncThunk(
  "editProfile",
  async (requestData, { rejectWithValue }) => {
    const API_URL =  `${process.env.REACT_APP_BASE_URL}/user/get-user`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "get",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: requestData,
    };
    

    try {
      const response = await axios(config);
      return response.data;
    } catch (error) {
      const {response} = error
    if(response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
      Cookies.remove("accessToken"); 
           
     }
      return rejectWithValue(error);
    }
  }
);

const getUserDetailsSlice = createSlice({
  name: "getUserDetails",
  initialState: {
    userDetails: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetails = action.payload.data.user; 
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default getUserDetailsSlice.reducer;
