import { createSlice } from "@reduxjs/toolkit";

const gameCreditData = [
  {
    id: 1,
    gameName: "Squid Slot",
    creditAmount: 5.0,
  },
  {
    id: 2,
    gameName: "Free Link olvera Street",
    creditAmount: 44.5,
  },
  {
    id: 3,
    gameName: "Free Link Rue Roayle",
    creditAmount: 15.3,
  },
  {
    id: 4,
    gameName: "Free Link China Street",
    creditAmount: 6.6,
  },
];

const initialState = {
  mainWallet: 1200.00,
  gameList: [...gameCreditData],
};
const gameCreditSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    collectIndividualCredit: (state, action) => {
      state.mainWallet = state.mainWallet + parseFloat(action.payload);
    },
    collectAllCredit: (state, action) => {
      let result = state.gameList.reduce(function (acc, obj) {
        return acc + obj.creditAmount;
      }, 0);

      result = Number(result.toFixed(2));
      state.mainWallet += result;
    },
    getRedeemAmount: (state, action) => {
      state.mainWallet += action.payload;
    },
  },
});

export const { collectIndividualCredit, collectAllCredit, getRedeemAmount } =
  gameCreditSlice.actions;

export default gameCreditSlice.reducer;
