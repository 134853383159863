import React, { useState } from 'react'
import { Modal } from "react-bootstrap-v5";
import Verify from '../../assets/images/images/logo/verify_account.svg'

const VerifyAccountPopup = ({showVerify, setShowVerify,handleVerifyAccountApi}) => { 
  const initialValues = {
    verificationCode: "",
  };


  const [verifData, setVerifData] = useState(initialValues);
  const [formError, setFormError] = useState({});

  const handleOnchange = (e) => {
    const fieldName = e.target.name;
    let err = { ...formError };
    delete err[fieldName];

    
    if (fieldName === "verificationCode" && isNaN(e.target.value)) {
      return;
    }
    setVerifData((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    }));
    setFormError({ ...err });
  };
  const validateForm = () => { 
    let err = {};
    if (verifData.verificationCode === "") {
      err.verificationCode = "Enter Verification Code";
    } else if (verifData.verificationCode.length !== 4) {
      err.verificationCode = "Enter a valid 4 digit code";
    } else {
      delete err.verificationCode;
    }
    setFormError({ ...err });
    return Object.keys(err).length < 1;
  };

  const handleVerify = ()=>{
    const isValid = validateForm()
    if(isValid){

      handleVerifyAccountApi(verifData?.verificationCode)
    }
  }

  const handleShow = ()=>{
    setShowVerify(false)
    setVerifData(initialValues)
  }

  return (
    <div>
    <Modal
    show={showVerify}
    onHide={()=>handleShow()}
    size="xl"
    className="login-modal modalClosebtn"
    centered
  >
    <Modal.Header closeButton className="btn-close-white"></Modal.Header>
    <Modal.Body className="customFlexClass ">
      <div className="row m-0">
        <div className="col-10 col-md-6 mx-auto">
          <div className="row m-0">
            <div className="col-md-12 col-lg-12 d-flex justify-content-start align-items-center mx-auto">
              <div className="heading-text mx-auto">
                <div className="d-flex justify-content-center align-items-center mb-3">
                  <div className="titleWithIcon">
                    <img style={{width:"100%"}} src={Verify}/>
                  </div>
                  <p className="p mb-0 section-heading customPopupsHeading transactionTitle">
                    Verify Your Account
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p className="text-white confirm-text mt-4">
          To continue, please enter 4-digit verification code we sent to your
          phone number.
        </p>
        <div className="password-input mt-2">
        <input
          className="form-control user-input mt-3"
          type="text"
          placeholder="Enter Verification Code"
          aria-label="verification"
          name="verificationCode" 
          value={verifData.verificationCode}
          onChange={handleOnchange}
          maxLength={4}
        /> 
      </div>
      <span className="errorMsg">{formError.verificationCode}</span>
      
      <div className="d-grid gap-2 signin-btn">
      <button
        className="btn btn-sign"
        data-bs-target="#signuppassdModal"
        data-bs-toggle="modal"
        type="button" 
        onClick={handleVerify}
      >
        Verify
      </button>
    </div>
        </div>
      </div>
    </Modal.Body>
  </Modal>
    </div>
  )
}

export default VerifyAccountPopup
