import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const editProfile = createAsyncThunk(
  "editProfile",
  async (requestData, { rejectWithValue }) => {
    const API_URL =  `${process.env.REACT_APP_BASE_URL}/user/update`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "post",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: requestData,
    };
    try {
      const response = await axios(config);

      return response.data;
    } catch (error) {
      const {response} = error
      if(response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
        Cookies.remove("accessToken");
      }

      let message = response?.data?.errors[0]?.description
      return rejectWithValue(error);
    }
  }
);

const editProfileSlice = createSlice({
  name: "editProfile",
  initialState: {
    editProfileResponse: [],
    loading: false,
    error: null,
    respStatus: null
  },
  extraReducers: (builder) => {
    builder
      .addCase(editProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(editProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.editProfileResponse = action.payload;
        state.respStatus = action.payload.respStatus
      })
      .addCase(editProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default editProfileSlice.reducer;
