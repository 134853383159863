import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showGameCredit } from "../../redux/counterSlice";
import Cookies from "js-cookie";
import axios from "axios";

import { 
  collectIndividualCredit,
} from "../../redux/gameCredit";
import { getMainWalletDetails } from "../../redux/mainWalletSlice";

const GameCreditSidebar = ({handleLaunchPrizeGame}) => {
  const dispatch = useDispatch();
  const { showCredit } = useSelector((state) => state.counter);
  const { gameList } = useSelector((state) => state.gameCredit);
  const [toggle,setToggle] = useState(false)
  const handleCollectCredit = (amount) => {
    dispatch(collectIndividualCredit(amount));
  };

  const { mainWalletDetails } = useSelector((state) => state.mainWallet);  
  const collectAllCredit = async () => {
    const API_URL = `${process.env.REACT_APP_BASE_URL}/user/collect-amount`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "get",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
    };

    try {
      const response = await axios(config);
      setToggle(!toggle) 
    } catch (error) {}
  };

  useEffect(() => {
    const accessToken = Cookies.get("accessToken");
    if (accessToken) {
      dispatch(getMainWalletDetails());
    }
  }, [toggle]);
  const collectIndvidiualCredit = async (walletId) => {
    {
      const API_URL = `${process.env.REACT_APP_BASE_URL}/user/collect-amount?gameWalletId=${walletId}`;
      const accessToken = Cookies.get("accessToken");
      const config = {
        method: "get",
        url: API_URL,
        headers: {
          Authorization: accessToken,
          type: "text",
        },
      };

      try {
        const response = await axios(config);
        setToggle(!toggle) 
      } catch (error) {}
    }
  };
  

  const handleGameLaunch = (gameId)=>{
    handleLaunchPrizeGame(gameId)
    dispatch(showGameCredit(false))
  }

  return (
    <div className="gameCreditSidbar" style={{ transition: "all .5s" }}>
      <div className="sidebarIconContainer mt-2">
        <span
          className="sidebarCloseIcon"
          onClick={() => dispatch(showGameCredit(!showCredit))}
        >
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>
      <div className="text-center p-3 mb-3">
        {mainWalletDetails?.gameCredits > 0 && <button
          className="collectinBtn text-white play-btn"
          onClick={collectAllCredit}
        >
          Collect All
        </button>}
      </div>
      {mainWalletDetails?.gameWallets?.map((eachGame) => (
        <div className="mainCard mb-1" key={eachGame.id}>
          <div className="sidebarGameContainer">
            <p className="sidebargame mb-0">
              {eachGame?.casinoGame?.name}{" "}
              </p>
              <p className="creditSpan">{eachGame.amount}</p>
            <div className="sidebarBtnContainer">
              <button
                className="sideBtn btn_1"
                onClick={() => collectIndvidiualCredit(eachGame?.id)}
              >
                Collect
              </button>
              <button className="sideBtn play-btn" onClick={()=>handleGameLaunch(eachGame?.gameId)}>Play</button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default GameCreditSidebar;
