import { configureStore } from '@reduxjs/toolkit'
import counterSlice from './counterSlice'
import gameCreditSlice from './gameCredit'
import trendingGameSlice from './trendingGameSlice'
import singUpSlice from './signUpSlice'
import loginSlice from './loginSlice'
import editProfileSlice from './editProfileSlice'
import getUserDetailsSlice from './getUserDetailsSlice'
import mainWalletSlice from './mainWalletSlice'
import updatePhoneNumberSlice from './updatePhoneNumberSlice'
import myOperatorsListSlice from './myOperatorsListSlice'
import notificationsCountSlice from './notificationsCountSlice'
import getBonusListSlice from "./bonusesSlice.js"
import lastWinnerListSlice from "./lastWinnersSlice.js"

export const store = configureStore({
  reducer: {
    counter:counterSlice,
    gameCredit: gameCreditSlice,
    gameData: trendingGameSlice,
    singUpData: singUpSlice,
    login: loginSlice,
    editProfile: editProfileSlice,
    userDetails: getUserDetailsSlice,
    mainWallet: mainWalletSlice,
    updateNumber: updatePhoneNumberSlice,
    myOperatorsList: myOperatorsListSlice,
    notificationsCount:notificationsCountSlice,
    bonusList: getBonusListSlice,
    lastWinnersLis:lastWinnerListSlice
  },
})