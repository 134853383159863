import { Modal } from "react-bootstrap-v5";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showMoreDetailsLastActivity } from "../../redux/counterSlice";

const MoreDetailsPopup = ({ moreDetails }) => {
  const dispatch = useDispatch();
  const { isShowMoreDetails } = useSelector((state) => state.counter);
  const handleClose = () => {
    dispatch(showMoreDetailsLastActivity(false));
  };
  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <>
      <Modal
        show={isShowMoreDetails}
        onHide={handleClose}
        size="xl"
        className="login-modal modalClosebtn  d-flex align-items-center justify-content-center"
      >
        <Modal.Header closeButton className="btn-close-white"></Modal.Header>
        <Modal.Body className="customFlexClass">
          <div className="row  m-0" style={{ width: "100%" }}>
            <div className="col-sm-12 col-md-12 col-lg-9  mx-auto pt-4 pb-4">
              <div className="row  m-0" style={{ width: "100%" }}>
                <div className="col-sm-12 mx-auto text-white ">
                  <div className="moreDetailsContainer">
                    <h1>Transaction Detail Info</h1>
                 <div className="lastActivityContainer">
                    <div className="row borderCustom">
                      <div className="col-6">
                        <div className="roundId pt-2 pb-2">Round ID</div>
                      </div>
                      <div className="col-6">
                        <div className="rountId pt-2 pb-2">
                          {moreDetails?.transactionId?.split("_")?.join("") ||
                            0}
                        </div>
                      </div>
                    </div>

                    <div className="row borderCustom">
                      <div className="col-6">
                       <div  className="roundId pt-2 pb-2">Date</div>
                      </div>
                      <div className="col-6">
                        <div className="pt-2 pb-2">{formatDate(moreDetails?.createdAt)}</div>
                      </div>
                    </div>

                    <div className="row borderCustom">
                      <div className="col-6">
                        <div className="roundId pt-2 pb-2">Operator ID</div>
                      </div>
                      <div className="col-6">
                        <div className="pt-2 pb-2">{moreDetails?.wallet?.operatorId}</div>
                      </div>
                    </div>

                    <div className="row borderCustom">
                      <div className="col-6">
                        <div className="roundId pt-2 pb-2">Balance</div>
                      </div>
                      <div className="col-6">
                        <div  className="pt-2 pb-2">{moreDetails?.afterBalance}</div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <div className="roundId pt-2 pb-2">Game</div>
                      </div>
                      <div className="col-6">
                        <div  className="pt-2 pb-2">{moreDetails?.casinoGame?.name}</div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MoreDetailsPopup;
