import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap-v5";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const ChangePhoneNumber = ({ changeNumber, setChangeNumber }) => {
  const [count, setCount] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState(false);
  const [currentNum, setCurrentNum] = useState("");
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();
  const initialValues = {
    newNum: "",
    password: "",
    checkboxAgree: false,
  };
  const [updatenumber, setUpdateNumber] = useState([]);
  const [phoneValid,setPhoneValid] = useState(false)
  const { userDetails } = useSelector((state) => state.userDetails);
  const [details, setDetails] = useState(initialValues);
  const [formError, setFormError] = useState({ initialValues });
  const handleClose = () => setChangeNumber(false);
  const onBlurHandler = (event) => {
    const fieldName = event.target.name;
    const fieldValue = details[fieldName];
    let err = { ...formError };
    if (fieldValue === "") {
      err[fieldName] = "Required!";
    } else if (fieldName === "newNum" && !phoneValid) {
      err[fieldName] = "Invalid phone number";
    } else if (fieldName === "checkboxAgree") {
      if (fieldValue === false) {
        err[fieldName] = "Required!";
      } else {
        delete err[fieldName];
      }
    } else {
      delete err[fieldName];
    }
    setFormError({ ...err });
  }; 

  const validateForm = () => {
    let err = {};
    if (details.newNum === "") {
      err.newNum = "Required!";
    } else {
      if (isNaN(details.newNum)) {
        err.newNum = "Enter valid number";
      } else if (!phoneValid) {
        err.newNum = "Invalid phone number";
      }
    }
    if (details.password === "") {
      err.password = "Required!";
    }
    if (details.checkboxAgree === false) {
      err.checkboxAgree = "Required!";
    }
    setFormError({ ...err });
    return Object.keys(err).length < 1;
  };

  const handleOnchange = (e) => {
    const fieldName = e.target.name;
    let err = { ...formError };
    delete err[fieldName];
    setFormError({ ...err });
    if (fieldName === "newNum" && isNaN(e.target.value)) {
      return;
    }
    setDetails((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    }));
  };

  useEffect(() => {
    const currentNum = `${userDetails.phoneCode} + ${userDetails.phone}`;
    setCurrentNum(currentNum);
  }, [changeNumber]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const generateOtp = () => {
    setMinutes(1);
    setSeconds(59);
  };
  const resendOTP = () => {
    setMinutes(1);
    setSeconds(59);
  };
  const updatePhoneNumberReq = async () => {
    const phoneWithCode = details.newNum;
    let totalLength = phoneWithCode.length;

    let countryCode = phoneWithCode.slice(0, totalLength - 10);
    let countrCodeLength = countryCode.length;
    let newPhoneNumber = phoneWithCode.slice(
      countrCodeLength,
      countrCodeLength + 10
    );

    const payload = {
      phone: userDetails?.phone,
      phoneCode: userDetails?.phoneCode,
      password: details.password,
      newPhone: newPhoneNumber,
      newPhoneCode: countryCode,
    };

    const API_URL =  `${process.env.REACT_APP_BASE_URL}/user/update-phone`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "post",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: payload,
    };
    try {
      const response = await axios(config);
      setUpdateNumber(response.data);
      if (response?.data?.data?.sentVerificationCode) {
        toast.success("OTP Sent");
        setCount(1);
        generateOtp();
      }
    } catch (error) {
      const { response } = error;
      if(response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
        Cookies.remove("accessToken");
      }
      let message = response?.data?.errors[0]?.description.split("_");
      let updatedMessage = message.join(" ");
      toast.error(updatedMessage);
    }
  };

  const verifyCodeReq = async () => {
    const phoneWithCode = details.newNum;
    let totalLength = phoneWithCode.length;

    let countryCode = phoneWithCode.slice(0, totalLength - 10);
    let countrCodeLength = countryCode.length;
    let newPhoneNumber = phoneWithCode.slice(
      countrCodeLength,
      countrCodeLength + 10
    );

    const payload = {
      newPhone: newPhoneNumber,
      newPhoneCode: countryCode,
      code: otp,
    };
    const API_URL =  `${process.env.REACT_APP_BASE_URL}/user/verify-phone-code`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "post",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: payload,
    };
    try {
      const response = await axios(config);
      if (response.data) {
        toast.success("Number updated successfully");
        setTimeout(() => {
          navigate("/");
          window.location.reload();
        }, 700);
      }
      return response.data;
    } catch (error) {
      const { response } = error;

      let message = response?.data?.errors[0]?.description.split("_");
      let updatedMessage = message.join(" ");
      toast.error(updatedMessage);
    }
  };

  const handleSubmitNewNumber = (e) => {
    e.preventDefault();

    const valid = validateForm();

    if (valid) {
      updatePhoneNumberReq();
    }
  };

  const handlePhoneChange = (value, country)=>{
    setPhone(value)
    setDetails({...details,newNum: value })
    setFormError({...formError, newNum:""})
    let validNum =  value.length - country.dialCode.length >= 10
    setPhoneValid(validNum) 
  }

  const handleSubmitOtp = (e) => {
    e.preventDefault();
    if (otp === "") {
      alert("Enter OTP");
    } else {
      verifyCodeReq();
    }
  };


  const handleSetOtp = (e)=>{
    if(isNaN(e.target.value)){
      return
    }
    setOtp(e.target.value)
  }

  const updateNumber = () => {
    return (
      <form onSubmit={handleSubmitNewNumber}>
        <div className="row">
          <div className="col-md-12 mb-2">
            <div className="user-input mt-2">
              <label
                htmlFor="inputState"
                className="form-label text-white mb-2 customPopUpLabel"
              >
                Current Phone Number*
              </label>
              <PhoneInput
                country={"us"}
                name="number"
                className="newPhoneInput"
                value={currentNum}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3 mb-1">
            <div className="user-input ">
              <label
                htmlFor="inputState"
                className="form-label text-white mb-2 customPopUpLabel "
              >
                New Phone Number*
              </label>
              <div className="password-input phoneNumberInput flagdropDwon">
                <PhoneInput
                  country={"us"}
                  name="number"
                  className="newPhoneInput"
                  value={details.newNum}
                  onChange={handlePhoneChange}
                  onBlur={onBlurHandler}
                  autoComplete="off" 
                />
              </div>
              <span className="errorMsg">{formError.newNum}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <div className="user-input ">
              <label
                htmlFor="inputState"
                className="form-label text-white mb-2 customPopUpLabel"
              >
                Password*
              </label>
              <input
                className="form-control user-input"
                name="password"
                type={password ? "text" : "password"}
                placeholder="Enter Password"
                aria-label="password"
                autoComplete="off"
                maxLength={16}
                value={details.password}
                onBlur={onBlurHandler}
                onChange={handleOnchange}
              />
              <i
                className={`${
                  password ? "bi bi-eye" : "bi bi-eye-slash"
                }  toggle-password`}
                onClick={() => setPassword(!password)}
              />
              <span className="errorMsg">{formError.password}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 ">
            <div className="mt-4 form-check">
              <div className="col  mt-3">
                <div className="rememberme">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="checkbox"
                    name="checkboxAgree"
                    checked={details.checkboxAgree}
                    onBlur={onBlurHandler}
                    onChange={handleOnchange}
                  />
                  <label
                    className="form-check-label label-text aggreText"
                    htmlFor="checkbox"
                  >
                    I agree to receive a Verification Code by text message.
                  </label>
                </div>
              </div>
            </div>
            <span className="errorMsg">{formError.checkboxAgree}</span>
          </div>
        </div>

        <div className="d-grid gap-2 signin-btn">
          <button
            className="btn btn-sign"
            type="submit"
            onClick={() => {
              generateOtp();
            }}
          >
            Generate OTP
          </button>
        </div>
      </form>
    );
  };

  const enterOtp = () => {
    return (
      <form onSubmit={handleSubmitOtp}>
        <div className="row">
          <div className="col-md-12 mt-2 mb-3">
            <div className="user-input">
              <label
                htmlFor="inputState"
                className="form-label text-white mb-2"
              >
                Enter OTP
              </label>
              <div className="row">
                <div className="col-8">
                  <input
                    className="form-control user-input"
                    type="text"
                    placeholder="Enter OTP"
                    aria-label="first-name"
                    maxLength={4}
                    value={otp}
                    onChange={(e) => handleSetOtp(e)}
                  />
                </div>
                <div className="col-4">
                  <button
                    className="btn header-btn resentOtpBtn w-100 resendBtnBorder"
                    disabled={seconds > 0 || minutes > 0}
                    onClick={() => {
                      resendOTP();
                      updatePhoneNumberReq();
                    }}
                  >
                    Resend
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-grid gap-2 signin-btn">
          {seconds > 0 || minutes > 0 ? (
            <p className="text-white">
              Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </p>
          ) : (
            <p className="text-white">Didn't recieve code?</p>
          )}
          <button className="btn btn-sign" type="submit">
            Submit
          </button>
        </div>
      </form>
    );
  };

  return (
    <>
      <Modal
        show={changeNumber}
        onHide={handleClose}
        size="xl"
        className="login-modal modalClosebtn"
      >
        <Modal.Header closeButton className="btn-close-white"></Modal.Header>
        <Modal.Body className="customFlexClass">
     
          <div className="row m-0">
            <div className="col-12 mx-auto">
              <div className="row m-0">
                <div className="col-md-12 col-lg-12 d-flex justify-content-start align-items-center mx-auto">
                  <div className="heading-text mx-auto">
                    <div className="d-flex justify-content-center align-items-center mb-3">
                      <div className="titleWithIcon">
                        <i className="fa-solid fa-arrows-rotate transactionIcon"></i>
                      </div>
                      <p className=" section-heading customPopupsHeading transactionTitle">
                        Change Phone Number
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {count === 0 && updateNumber()}
              {count === 1 && enterOtp()}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChangePhoneNumber;
