import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import swal from "sweetalert";
import Cookies from "js-cookie";

export const login = createAsyncThunk(
  "login",
  async (requestData, { rejectWithValue }) => {
    const API_URL =  `${process.env.REACT_APP_BASE_URL}/user/login`;
    try {
      const response = await axios.post(API_URL, requestData);
      if (response.status === 200) {
        const accessToken = response.data.data.accessToken;
        Cookies.set("accessToken", accessToken);
        window.location.reload();
      }
      return response.data.data.accessToken;
    } catch (error) {
      let message = error?.response?.data?.errors[0]?.description.split("_").join(" ")
      swal({
        title: `${message}`,
        icon: "warning",
        type: "warning",
        showCancelButton: false,
        confirmButtonClass: "",
        confirmButtonText: "Ok",
        closeOnConfirm: false,
      }).then((willDelete) => {
        if (willDelete) {
          window.location.reload(); 
        }
      });
      return rejectWithValue(error);
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState: {
    accessToken: "",
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.accessToken = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;  
      });
  },
});

export default loginSlice.reducer;

