import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify"; 

// Get bonus List
export const getBonusedListData = createAsyncThunk("getBonusListData", async () => {
  const API_URL = `${process.env.REACT_APP_BASE_URL}/bonus`;
  const accessToken = Cookies.get("accessToken");
  const config = {
    method: "get",
    url: API_URL,
    headers: {
      Authorization: accessToken,
      type: "text",
    },
    data: {},
  };
  try {
    const response = await axios(config);
    return response?.data?.data?.bonus;
  } catch (error) {
    if(error?.response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
      Cookies.remove("accessToken") 
      window.location.href="/"
        
    }else{ 
      toast?.error(error?.response?.data?.errors[0]?.description) 
    }
    throw error;
  }
});

// Get wagered details
export const getWageredBalnce = createAsyncThunk("getWageredBalnce", async () => {
  const API_URL = `${process.env.REACT_APP_BASE_URL}/bonus/wagered`;
  const accessToken = Cookies.get("accessToken");
  const config = {
    method: "get",
    url: API_URL,
    headers: {
      Authorization: accessToken,
      type: "text",
    },
    data: {},
  };
  try {
    const response = await axios(config); 
    return response?.data?.data 
  } catch (error) {
    // Handle error if needed
    if(error?.response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
      Cookies.remove("accessToken") 
      window.location.href="/"
        
    }else{ 
      toast?.error(error?.response?.data?.errors[0]?.description) 
    }
    
    throw error;
  }
});

// Get  details (Read More)
export const getReadMoreDetails = createAsyncThunk("getReadMoreDetails", async (bonusId) => {
  const API_URL = `${process.env.REACT_APP_BASE_URL}/bonus/detail?bonusId=${bonusId}`;
  const accessToken = Cookies.get("accessToken");
  const config = {
    method: "get",
    url: API_URL,
    headers: {
      Authorization: accessToken,
      type: "text",
    },
    data: {},
  };
  try {
    const response = await axios(config);
    return response?.data?.data?.bonusDetails?.description;
  } catch (error) {
    if(error?.response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
      Cookies.remove("accessToken") 
      window.location.href="/"
        
    }else{ 
      toast?.error(error?.response?.data?.errors[0]?.description) 
    }
    throw error;
  }
});

//Get Bonus credit
export const getBonusCredit = createAsyncThunk("getBonusCredit", async (bonusId) => {
  const API_URL = `${process.env.REACT_APP_BASE_URL}/bonus/credit-details?bonusId=${bonusId}`;
  const accessToken = Cookies.get("accessToken");
  const config = {
    method: "get",
    url: API_URL,
    headers: {
      Authorization: accessToken,
      type: "text",
    },
    data: {},
  };
  try {
    const response = await axios(config); 
    return response?.data;
  } catch (error) { 
    if(error?.response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
      Cookies.remove("accessToken") 
      window.location.href="/"
        
    }else{ 
      toast?.error(error?.response?.data?.errors[0]?.description) 
    }
    throw error;
  }
});


// Collect Bonus 

export const collectBonus = createAsyncThunk("collectBonus", async (bonusId) => {
  const API_URL = `${process.env.REACT_APP_BASE_URL}/bonus/collect-bonus`;
  const accessToken = Cookies.get("accessToken");
  const config = {
    method: "get",
    url: API_URL,
    headers: {
      Authorization: accessToken,
      type: "text",
    },
    data: {},
  };
  try {
    const response = await axios(config); 
    return response?.data;
  } catch (error) { 
    if(error?.response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
      Cookies.remove("accessToken") 
      window.location.href="/"
        
    }else{ 
      toast?.error(error?.response?.data?.errors[0]?.description) 
    }
    throw error;
  }
});





const getBonusListSlice = createSlice({
  name: "getBonusList",
  initialState: {
    bonusList: [],
    readMoreDetails: [],
    wageredBalnce:[], 
    isBonusCreditLoading:false,
    bonusCredit:[],
    loading: false,
    error: null,
    isBonusDeatilsLoading: false,
    collectBonusLoading:false
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBonusedListData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBonusedListData.fulfilled, (state, action) => {
        state.loading = false;
        state.bonusList = action.payload;
      })
      .addCase(getBonusedListData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getReadMoreDetails.pending, (state) => {
        state.isBonusDeatilsLoading = true;
      })
      .addCase(getReadMoreDetails.fulfilled, (state, action) => {
        state.isBonusDeatilsLoading = false;
        state.readMoreDetails = action.payload;
      })
      .addCase(getReadMoreDetails.rejected, (state, action) => {
        state.isBonusDeatilsLoading = false;
        state.error = action.payload;
      })
      .addCase(getWageredBalnce.pending, (state) => {
        state.isBonusDeatilsLoading = true;
      })
      .addCase(getWageredBalnce.fulfilled, (state, action) => {
        // state.isBonusDeatilsLoading = false;
        state.wageredBalnce = action.payload;
      })
      .addCase(getWageredBalnce.rejected, (state, action) => {
        state.isBonusDeatilsLoading = false;
        state.error = action.payload;
      })
      .addCase(getBonusCredit.pending, (state) => {
        state.isBonusCreditLoading = true;
      })
      .addCase(getBonusCredit.fulfilled, (state, action) => {
        state.isBonusCreditLoading = false;
        state.bonusCredit = action.payload;
      })
      .addCase(getBonusCredit.rejected, (state, action) => {
        state.isBonusCreditLoading = false;
        state.error = action.payload;
      })
      
      .addCase(collectBonus.pending, (state) => {
        state.collectBonusLoading = true;
      })
      .addCase(collectBonus.fulfilled, (state, action) => {
        state.collectBonusLoading = false;
        state.bonusCredit = action.payload;
      })
      .addCase(collectBonus.rejected, (state, action) => {
        state.collectBonusLoading = false;
        state.error = action.payload;
      });
      
  },
});

export default getBonusListSlice.reducer;
