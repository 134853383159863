import React from 'react'

const BonusTermsConditions = () => {
  return (
    <div className='bonusTermsCondition'>
       <div className="container">
            <div className="row">
            <div className="col-md-10 mx-auto text-white">
            <div className="readMore">
            <h1 className="mainTiltle">
            <span className="icon"> 
            <i className="fa-solid fa-gift"></i>
            </span>

           Bonuses Terms & Conditions
          </h1>
              <ol className="bonusList mt-4">
                <li className="list">
                Bonuses on LuckyReels.net are available to players only if they appear on the Bonuses
                page of the site. If a bonus is not meant for a player, it will not be shown as available.
                Bonus offers can be received via text message or other communication methods. Any
                bonuses and winnings from them not intended for the player may be forfeited.
                </li>
                <li className="list">
                On the "Bonuses" page, you can activate different bonuses, which include the “Deposit
                bonus” and “Non-deposit bonus”. You can only claim one bonus at a time and only one
                bonus can be claimed each day.
                </li>
                <li className="list">  Every bonus comes with specific conditions, such as minimum deposit, maximum
                deposit, bonus amount, and play-through requirement also known as the wagering
                requirement. You can find detailed information about each bonus in its "Read More"
                section.
                </li>
                <li className="list">A bonus can only be activated when an active operator is connected and will be
                associated with your selected operator.</li>
                <li className="list">A player can refuse any bonus before meeting any wagering conditions by request. To
                learn more, please contact the LuckyReels support team.
                </li>
                <li className="list">Any winnings from "Free Spins," "Bonus Games," or "Feature Games" that result from
                bonus credits will be removed once the bonus has been wagered, lost, or forfeited.</li>
                <li className="list">To claim a “Deposit Bonus”, a player must first make a qualifying deposit as specified in
                the bonus "Read More" section. After that, the player should navigate to the "Bonuses"
                page on the site, click the "Activate" button for the desired bonus, and confirm the
                activation in the next window.</li>
                <li className='list'>
                To claim any “Non-Deposit Bonus”, the player must go to the "Bonuses" page on the site,
click the "Activate" button for the bonus, and confirm the activation in the next window.
9. Once activated, the bonus will be added to the player's bonus account balance. It will be
subject to the site's general Terms and Conditions, including specific Bonus Terms and
Conditions and wagering requirements.
                </li>
              <li className="list">  Once activated, the bonus will be added to the player's bonus account balance. It will be
              subject to the site's general Terms and Conditions, including specific Bonus Terms and
              Conditions and wagering requirements.</li>

                <li className='list'>
                 While a bonus is active, the credits in the player's bonus account will be used first for
                "Play for Prizes" mode before any funds from the player's games wallet or main wallet
                are used.
                </li>
                <li className='list'>
                All winnings earned while playing with bonus credits are added to the player's bonus
                account balance.                
                </li>
                <li className='list'>
                The player has 15 days from the time the bonus is activated to meet the wagering
requirements. If they do not meet these requirements within that time, the bonus and any
pending winnings will expire and be removed from their account
                </li>

                <li className='list'>
                All bonuses need to be wagered x times. This means the player must place bets using
                the bonus balance that total x times the amount of the bonus credits received. Once
                these criteria are met, any winnings can be moved to the player's main wallet balance.
                (To be decided by site)
                </li>
                <li className='list'>
                
                Once the player meets all the bonus requirements, up to 2,500.00 bonus credits, will be
automatically added to the current Game's Wallet. Any bonus balance over this limit will
be forfeited.
</li>
<li className='list'>
If the player's bonus account balance drops under 0.50 bonus credits, the bonus will be
automatically closed, and any remaining bonus funds will be forfeited.

</li>
<li className="list">Multiple accounts are not allowed on LuckyReels.net. Creating more than one account to
claim bonuses is considered bonus abuse and will lead to the confiscation of credits and
account deletion.</li>
<li className="list">
. If an audit shows that a player is using strategies, exploiting software or system bugs, or
engaging in any activity that LuckyReels.net considers abusive, we reserve the right to
cancel any bonuses and/or winnings obtained will lead to account deletion through such
fraudulent behavior</li>
<li className="list">These Bonus Terms and Conditions, as posted on luckyreels.net and updated from time
to time, are part of the overall Terms and Conditions. The official version is in English.
Translations into other languages are provided as a courtesy. If there are any differences
between the English version and a translation, the English version will take precedence</li>
<li className="list">
Luckyreels.net can cancel or change the Terms and Conditions at any time without
giving prior notice.</li>
<li className="list">
In case of any dispute, the decision made by LuckyReels.net will be final.</li>
              </ol>
            </div>
            </div>
       </div>
    </div>
    </div>
  )
}

export default BonusTermsConditions
