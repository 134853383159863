import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../../src/assets/images/images/logo/new_logo1.png";
import Flag1 from "../../assets/images/images/flag/Flag_of_Mexico.png";
import UsaFlag from "../../assets/images//images/flag/usa_flag.png";
import DeactivateProfile from "../UserProfilePopUps/DeactivateProfile";
import ChangePassword from "../UserProfilePopUps/ChangePassword";
import ChangePhoneNumber from "../UserProfilePopUps/ChangePhoneNumber";
import axios from "axios";
import LastActivityPop from "../UserProfilePopUps/LastActivityPop";

import EditProfile from "../UserProfilePopUps/EditProfilePopup";
import RedeemPopup from "../UserProfilePopUps/AddCreditPopup";
import TransactionPopup from "../UserProfilePopUps/TransactionPopup";
import NotificationsPopup from "../UserProfilePopUps/NotificationsPopup";
import { useDispatch, useSelector } from "react-redux";
import { changeLaguage } from "../../redux/counterSlice";
import swal from "sweetalert";
import { io } from "socket.io-client";
import Cookies from "js-cookie"; 
import { setNotificationsCount } from "../../redux/notificationsCountSlice";
import TransferToFriendPopup from "../UserProfilePopUps/TransferToFriendPopup";

const SOCKET_NAMESPACES = {
  PRIVATE: "/private",
};

const SOCKET_LISTENERS = {
  NOTIFICATION: "notification",
  WALLET: "wallet",
};

const SOCKET_EMITTERS = {
  USER_UNREAD_NOTIFICATION_COUNT: `${SOCKET_NAMESPACES.PRIVATE}/unreadNotification`,
};

const socketInit = () => {
  const API_BASE_URL = process.env.REACT_APP_BASE_URL; // Base URL from environment variables
  let result = API_BASE_URL.includes("dev-api")
    ? "https://dev-api.luckyreels.net"
    : "https://api.luckyreels.net";

  // Initialize socket connection
  const accessToken = Cookies.get("accessToken");

  const socket = io(result + SOCKET_NAMESPACES.PRIVATE, {
    transports: ["websocket"],
    autoConnect: true,
    auth: {
      accessToken: accessToken,
    },
    path: "/api/socket",
    timeout: 1000,
  });
  return socket;
};

const Sidebar = ({ showSidebar, setShowSideBar }) => {
  const [redeem, setRedeem] = useState(false);
  const [transaction, setTransaction] = useState(false);
  const [transerToFriend, setTranserToFriend] = useState(false);
  const [lastActivity, setLastActivity] = useState(false);
  const navigate = useNavigate();
  const { notificationCounts } = useSelector(
    (state) => state.notificationsCount
  );

  const { activeOpertor } = useSelector((state) => state.myOperatorsList);

  const [editProfilePopup, setEditProfilePopup] = useState(false);
  const [changeNumber, setChangeNumber] = useState(false);
  const [chnangePassword, setChnangePassword] = useState(false);
  const [deactivate, setDeactivate] = useState(false);
  const [notifications, setNotifications] = useState(false);

  const location = useLocation();
  const root = location.pathname;
  const [showProfile, setShowProfile] = useState(false);
  const [showOperator, setShowOperator] = useState(false);
  const [selectFlag, setSelectFlag] = useState(false);
  const [selectFlag2, setSelectFlag2] = useState(false);
  const dispatch = useDispatch();
  const accessToken = Cookies.get("accessToken");
  const { userDetails } = useSelector((state) => state.userDetails);
  const { language } = useSelector((state) => state.counter);

  const handleFlag1 = () => {
    setSelectFlag(true);
    setSelectFlag2(false);
    dispatch(changeLaguage("en"));
  };

  const handleFlag2 = () => {
    setSelectFlag2(true);
    setSelectFlag(false);
    dispatch(changeLaguage("spanish"));
  };
  useEffect(() => {
    if (accessToken) {
      const socket = socketInit();
      socket.emit(SOCKET_EMITTERS.USER_UNREAD_NOTIFICATION_COUNT, {}, (data) =>
        console.log("")
      );
      socket.on(SOCKET_LISTENERS.NOTIFICATION, (data) => {
        dispatch(setNotificationsCount(data?.notificationUnreadCount));
      });
    }
  }, []);

  const getLogOut = async () => {
    const API_URL = `${process.env.REACT_APP_BASE_URL}/user/logout`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "post",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
    };
    try {
      const response = await axios(config);
      navigate("/");

      window.location.reload();
    } catch (error) {
      const { response } = error;
    }
  };
  const handleLogout = () => {
    getLogOut();
    Cookies.remove("accessToken");
    setShowSideBar(false);
  };

  const handleTransfertoFriend = () => {
    swal({
      title: "Feature Not Available",
      text: "This feature is not available right now. It may be available in the future.",
      icon: "warning",
      type: "warning",
      showCancelButton: false,
      confirmButtonClass: "",
      confirmButtonText: "Ok",
      closeOnConfirm: false,
      customClass: {
        content: "my-custom-text-class", // Add your custom class here
      },
    });
    // navigate("/transferToFriend")
  };

  const handleNotification = () => {
    setNotifications(true);
    dispatch(setNotificationsCount(0));
  };

  return (
    <>
      <div
        className={`${showSidebar ? "" : "hideSidebar"} sidebar d-lg-none`}
        style={{ transition: "all .5s ease-in-out" }}
      >
        <div className="sidebarIconContainer">
          <span
            className="sidebarCloseIcon"
            onClick={() => setShowSideBar(false)}
          >
            <i className="fa-solid fa-xmark"></i>
          </span>
        </div>
        <div className="sidebarOptionsContianer">
          <div className="sidebarLogo">
            <Link to="/" onClick={() => setShowSideBar(false)}>
              <img src={Logo} alt="logo" className="sidebarLogoImg" />
            </Link>
          </div>

          {accessToken !== undefined && (
            <div className="button d-flex flex-column scrollBar">
              <button
                type="button"
                className="btn header-btn-gameCredit text-nowrap mt-2 btnSidebarUpdate"
              >
                <div
                  className="d-flex justify-content-between align-items-center"
                  onClick={() => setShowOperator(!showOperator)}
                >
                  <div className="mb-0 userProfileText mb-2">
                    <p className="mb-0">
                      {language === "spanish"
                        ? "Mis operadoras"
                        : "My Operators"}{" "}
                      : {activeOpertor?.operator?.id || 0}
                    </p>
                  </div>
                  <span className="userProfileDropdownIcon">
                    <i
                      className={`fa-solid fa-chevron-${
                        showOperator ? "up" : "down"
                      }`}
                    ></i>
                  </span>
                </div>
                <ul
                  className={`${
                    showOperator ? "showUserProfile " : "hidUserProfilDetails"
                  } mobileUserProfileList`}
                >
                  <li
                    className="userList header-btn sidebarPopupsList"
                    onClick={() => {
                      navigate("/myOperators");
                      setShowSideBar(false);
                    }}
                  >
                    <span className="userProfileIcon">
                      <i className="fa-solid fa-user-group me-2"></i>
                    </span>
                    {language === "spanish" ? "Mis operadoras" : "My Operators"}
                  </li>
                  <li
                    className="userList modalBtnfont sidebarPopupsList"
                    onClick={() => {
                      setShowSideBar(false);
                      navigate("/redeem");
                    }}
                  >
                    <span className="userProfileIcon">
                      <i className="fa-solid fa-bag-shopping me-2"></i>
                    </span>
                    {language === "spanish" ? "Canjear" : "Redeem"}
                  </li>
                  <li
                    className="userList sidebarPopupsList"
                    onClick={() => setTransaction(true)}
                  >
                    <span className="userProfileIcon">
                      <i className="fa-solid fa-money-bill-transfer me-2"></i>
                    </span>
                    {language === "spanish" ? "Actas" : " Transactions"}
                  </li>
                  <li
                    className="userList sidebarPopupsList modalBtnfont"
                    onClick={() => handleTransfertoFriend()}
                  >
                    <span className="userProfileIcon">
                      <i className="fa-solid  fa-share me-2"></i>
                    </span>
                    {language === "spanish"
                      ? "Transer a una amiga"
                      : " Transer To a Friend"}
                  </li>
                  <li
                    className="userList sidebarPopupsList"
                    onClick={() => setLastActivity(true)}
                  >
                    <span className="userProfileIcon">
                      <i className="fa-solid fa-layer-group me-2"></i>
                    </span>
                    {language === "spanish"
                      ? "Última actividad"
                      : " Last Activity"}
                  </li>
                </ul>
              </button>

              <button
                type="button"
                className="btn header-btn-gameCredit text-nowrap mt-2 scrollBar pe-0 ps-0 btnSidebarUpdate"
              >
                <div
                  className="d-flex justify-content-between align-items-center"
                  onClick={() => setShowProfile(!showProfile)}
                >
                  <p className="mb-0 userProfileText mb-2">
                    {userDetails?.username}
                  </p>
                  <span className="userProfileDropdownIcon">
                    <i
                      className={`fa-solid fa-chevron-${
                        showProfile ? "up" : "down"
                      }`}
                    ></i>
                  </span>
                </div>
                <ul
                  className={`${
                    showProfile ? "showUserProfile" : "hidUserProfilDetails"
                  } mobileUserProfileList`}
                >
                  <li
                    className="userList sidebarPopupsList"
                    onClick={() => setEditProfilePopup(true)}
                  >
                    <span className="userProfileIcon">
                      <i className="fa-solid fa-user-pen me-2"></i>
                    </span>
                    {language === "spanish" ? "Editar perfil" : "Edit Profile"}
                  </li>
                  <li
                    className="userList sidebarPopupsList"
                    onClick={() => setChangeNumber(true)}
                  >
                    <span className="userProfileIcon">
                      <i className="fa-solid fa-arrows-rotate me-2"></i>
                    </span>
                    <span className="changePhoneNumberCustom">
                      {language === "spanish"
                        ? "Cambiar número de teléfono"
                        : "Change Phone Number"}{" "}
                    </span>
                  </li>
                  <li
                    className="userList sidebarPopupsList"
                    onClick={() => setChnangePassword(true)}
                  >
                    <span className="userProfileIcon">
                      <i className="fa-solid fa-user-pen me-2"></i>
                    </span>
                    {language === "spanish"
                      ? "Cambiar la contraseña"
                      : "Change Password"}
                  </li>
                  <li
                    className="userList sidebarPopupsList"
                    onClick={() => setDeactivate(true)}
                  >
                    <span className="userProfileIcon">
                      {userDetails?.isActive ? (
                        <i className="fa-solid fa-user-slash"></i>
                      ) : (
                        <i className="fa-solid fa-user"></i>
                      )}
                    </span>
                    {userDetails?.isActive
                      ? language === "spanish"
                        ? "Desactivar perfil"
                        : "Deactivate Profile"
                      : language === "spanish"
                      ? "Activar perfil"
                      : "Activate Profile"}
                  </li>
                  <li
                    className="userList modalBtnfont sidebarPopupsList"
                    onClick={handleLogout}
                  >
                    <span className="userProfileIcon">
                      <i className="fa-solid fa-arrow-right"></i>
                    </span>
                    {language === "spanish" ? "Cerrar sesión" : "Log Out"}
                  </li>
                </ul>
              </button>
            </div>
          )}

          <ul className="sidebarOptionList">
            <Link
              to="/"
              className={`${
                root === "/" ? "activeNavSide" : "sidebarLink"
              } sidebarIptions`}
            >
              <li className="sidebarList" onClick={() => setShowSideBar(false)}>
                <div className="sidebarIcon">
                  <i className="fa-solid fa-house"></i>
                </div>
                <p>{language === "spanish" ? "Hogar" : "Home"}</p>
              </li>
            </Link>
            <Link
              className={`${
                root === "/notifications" ? "activeNavSide" : "sidebarLink"
              } sidebarIptions`}
            >
              {" "}
              <li className="sidebarList" onClick={() => handleNotification()}>
                <div className="sidebarIcon">
                  <i className="fa-solid fa-bell"></i>
                </div>
                <div className="notificationBadge">
                  {language === "spanish" ? "Notificaciones" : "Notifications"}

                  {notificationCounts > 0 && Cookies.get("accessToken") && (
                    <div className="notificationCount">
                      {notificationCounts < 9 ? notificationCounts : `10+` || 0}
                    </div>
                  )}
                </div>
              </li>
            </Link>
            <Link
              to="/bonus"
              className={`${
                root === "/bonus" ? "activeNavSide" : "sidebarLink"
              } sidebarIptions`}
            >
              {" "}
              <li
                className="sidebarList"
                onClick={() => {
                  setShowSideBar(false);
                  navigate("/bonus");
                }}
              >
                <div className="sidebarIcon">
                  <i className="fa-solid fa-money-bill-trend-up"></i>
                </div>
                <p>{language === "spanish" ? "Prima" : "Bonus"}</p>
              </li>
            </Link>
            <Link
              to="/operators"
              className={`${
                root === "/operators" ? "activeNavSide" : "sidebarLink"
              } sidebarIptions`}
            >
              {" "}
              <li className="sidebarList" onClick={() => setShowSideBar(false)}>
                <div className="sidebarIcon">
                  <i className="fa-solid fa-user-group"></i>
                </div>
                <p>{language === "spanish" ? "Operadores" : "Operators"}</p>
              </li>
            </Link>
            <Link
              to="/contactus"
              className={`${
                root === "/contactus" ? "activeNavSide" : "sidebarLink"
              } sidebarIptions`}
            >
              {" "}
              <li className="sidebarList" onClick={() => setShowSideBar(false)}>
                <div className="sidebarIcon">
                  <i className="fa-solid fa-headphones"></i>
                </div>
                <p>
                  {language === "spanish"
                    ? "Contacta con nosotras"
                    : "Contact us"}
                </p>
              </li>
            </Link>
            <Link
              to="/t&c"
              className={`${
                root === "/t&c" ? "activeNavSide" : "sidebarLink"
              } sidebarIptions`}
            >
              {" "}
              <li className="sidebarList" onClick={() => setShowSideBar(false)}>
                <div className="sidebarIcon">
                  <i className="fa-regular fa-pen-to-square"></i>
                </div>
                <p>
                  {language === "spanish"
                    ? "Términos y condiciones"
                    : "Terms & Conditions"}
                </p>
              </li>
            </Link>
            <Link
              to="/privacy"
              className={`${
                root === "/privacy" ? "activeNavSide" : "sidebarLink"
              } sidebarIptions`}
            >
              <li className="sidebarList" onClick={() => setShowSideBar(false)}>
                <div className="sidebarIcon">
                  <i className="fa-solid fa-shield-halved"></i>
                </div>
                <p>
                  {language === "spanish"
                    ? "Política de privacidad"
                    : "Privacy Policy"}{" "}
                </p>
              </li>
            </Link>
            <li
              className="sidebarList sidebarIptions"
              onClick={() => {
                setShowSideBar(false);
                navigate("/faq");
              }}
            >
              <div className="sidebarIcon">
                <i className="fa-solid fa-clipboard-question"></i>
              </div>
              {language === "spanish" ? "Preguntas más frecuentes" : "F.A.Q"}
            </li>
      
            {accessToken !== undefined && (
              <li
                className="sidebarList sidebarIptions d-flex align-items-center"
                onClick={() => {
                  setShowSideBar(false);
                  handleLogout();
                }}
              >
                <div className="sidebarIcon d-flex align-items-center">
                  <i className="fa-solid fa-arrow-right"></i>
                </div>
                <div className="sidebarList ">
                  {language === "spanish" ? "Cerrar sesión" : "Log Out"}
                </div>
              </li>
            )}
          </ul>

          <div className="sidebarBottom">
            <div
              className={`${selectFlag ? "actveFlag" : ""} flag`}
              onClick={handleFlag1}
            >
              <img src={UsaFlag} alt="flag" className="flagImg" />
            </div>
            <div
              className={`${selectFlag2 ? "actveFlag" : ""} flag`}
              onClick={handleFlag2}
            >
              <img src={Flag1} alt="flag" className="flagImg" />
            </div>
          </div>
        </div>
      </div>

      <RedeemPopup myOperredeemators redeem={redeem} setRedeem={setRedeem} />

      <TransactionPopup
        transaction={transaction}
        setTransaction={setTransaction}
      />
      {/*<TransferToFriendPopup
        transerToFriend={transerToFriend}
        setTranserToFriend={setTranserToFriend}
      />*/}
      <LastActivityPop
        lastActivity={lastActivity}
        setLastActivity={setLastActivity}
      />
      <EditProfile
        editProfilePopup={editProfilePopup}
        setEditProfilePopup={setEditProfilePopup}
      />
      <ChangePassword
        chnangePassword={chnangePassword}
        setChnangePassword={setChnangePassword}
      />
      <DeactivateProfile
        deactivate={deactivate}
        setDeactivate={setDeactivate}
      />
      <ChangePhoneNumber
        changeNumber={changeNumber}
        setChangeNumber={setChangeNumber}
      />
      <NotificationsPopup
        notifications={notifications}
        setNotifications={setNotifications}
      />
    </>
  );
};

export default Sidebar;
