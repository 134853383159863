import { useState } from "react";
import { getUserData } from "../../../redux/signUpSlice";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import swal from "sweetalert";
import {  toast } from "react-toastify";

const SecondStep = ({minutes, seconds, resendOTP }) => {
  const initialValues = {
    verificationCode: "",
  };
  const dispatch = useDispatch();
  const [verifData, setVerifData] = useState(initialValues);
  const [formError, setFormError] = useState({});
  const { singupApiResponse } = useSelector((state) => state.singUpData);
  const handleOnchange = (e) => {
    const fieldName = e.target.name;
    let err = { ...formError };
    delete err[fieldName];

    
    if (fieldName === "verificationCode" && isNaN(e.target.value)) {
      return;
    }
    setVerifData((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    }));
    setFormError({ ...err });
  };
  const validateForm = () => {
    const code = singupApiResponse?.data.user.verificationCode;
    let err = {};
    if (verifData.verificationCode === "") {
      err.verificationCode = "Enter Verification Code";
    } else if (verifData.verificationCode.length !== 4) {
      err.verificationCode = "Enter a valid 4 digit code";
    } else {
      delete err.verificationCode;
    }
    setFormError({ ...err });
    return Object.keys(err).length < 1;
  };
  const handleSubmitCode = () => {
    dispatch(getUserData(verifData));
  };
  const handleVerifyAccountApi = async () => {
    const {  id } = singupApiResponse?.data.user;
    const apiBody = {
      code: verifData.verificationCode,
      userId: id,
    };
    const API_URL =  `${process.env.REACT_APP_BASE_URL}/user/verify-account`;
    try {
      const response = await axios.post(API_URL, apiBody);
      if (response?.status === 200) {
        swal({
          title: "Account Verified",
          icon: "success",
          type: "success",
          showCancelButton: false,
          confirmButtonClass: "",
          confirmButtonText: "Ok",
          closeOnConfirm: false,
        }).then((willDelete) => {
          if (willDelete) {
            window.location.reload();
          }
        });
      }
    } catch (error) {
      let message = error?.response?.data?.errors[0]?.description.split("_");
      let updatedMessage = message.join(" ");
      toast.error(updatedMessage);
    }
  };

  const hanldeResendCode = async () => {
    resendOTP();
    try {
      const { id } = singupApiResponse?.data.user;
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/user/resend-otp`,
      { userId: id }
    );
    if(response?.status===200){
      toast.success("OTP sent")
    }
    } catch (error) {
      let message = error?.response?.data?.errors[0]?.description.split("_");
      let updatedMessage = message.join(" ");
      toast.error(updatedMessage);
    }
  };

  const handleSubmit = () => {
    const isValid = validateForm();
    if (isValid) {
      handleVerifyAccountApi();
    }
  };

  return (
    <div className="row signupScreen">
      <div className="col-12 col-md-6 col-lg-8 mx-auto">
        <div className="verification">
          <div className="welcome mt-3">
            <p className="welcome-text">Verify Your Account</p>
          </div>
          <p className="text-white confirm-text mt-4">
            To continue, please enter 4-digit verification code we sent to your
            phone number.
          </p>
        </div>
        <div className="password-input mt-2">
          <input
            className="form-control user-input mt-3"
            type="text"
            placeholder="Enter Verification Code"
            aria-label="verification"
            name="verificationCode"
            value={verifData.verificationCode}
            onChange={handleOnchange}
            maxLength={4}
          />
          <span className="errorMsg">{formError.verificationCode}</span>
        </div>
        {/*Add here resend OTP counting message*/}
        <div className="d-grid gap-2 signin-btn mt-3">
          {seconds > 0 || minutes > 0 ? (
            <p className="text-white">
              Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </p>
          ) : null}
        </div>
        <div className="rememberme mt-3">
          <p className=" text-white confirm-text">
            Did not receive a text message?{" "}
            <a className="text-decoration-none">
              <button
                className="brand-name sendAgainBtn"
                disabled={seconds > 0 || minutes > 0}
                onClick={() => hanldeResendCode()}
              >
                Send again{" "}
              </button>
            </a>{" "}
            or{" "}
            <a href="#" className="text-decoration-none">
              <span className="brand-name requestCall">request a voice call. </span>{" "}
            </a>
          </p>
        </div>
        <div className="d-grid gap-2 signin-btn">
          <button
            className="btn btn-sign"
            data-bs-target="#signuppassdModal"
            data-bs-toggle="modal"
            type="button"
            onClick={() => handleSubmit()}
          >
            Verify
          </button>
        </div>
      </div>
    </div>
  );
};

export default SecondStep;
