import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const myOperatorsList = createAsyncThunk(
  "operatorsList",
  async (requestData, { rejectWithValue }) => {
    const API_URL = `${process.env.REACT_APP_BASE_URL}/my-operators`
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "get",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: requestData,
    };

    try {
        const response = await axios(config);
        
  
        return response?.data?.data
    } catch (error) {
      const {response} = error
      if(response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
        Cookies.remove("accessToken");
      }
 
      return rejectWithValue(error);
    }
  }
);

const myOperatorsListSlice = createSlice({
  name: "myOperatorsList",
  initialState: {
    operatorsList: [],
    activeOpertor: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(myOperatorsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(myOperatorsList.fulfilled, (state, action) => {
        state.loading = false;
        state.operatorsList = action.payload;
        state.activeOpertor = action?.payload?.find((operator)=> operator?.activeStatus==1)
      })
      .addCase(myOperatorsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default myOperatorsListSlice.reducer;
