import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { SignVerification } from "./signupSteps/FirstStep";
import { LoginModal } from "./Login";
import { useDispatch, useSelector } from "react-redux";
import { modalKey } from "../../redux/counterSlice";
import ThirdStep from "./signupSteps/ThirdStep";
import SecondStep from "./signupSteps/SecondStep";
import { useEffect, useState } from "react"; 

const ModalPanel = ({ setStep, setShowModal, setShow }) => {
  const dispatch = useDispatch();
  const key = useSelector((state) => state.counter.iskey);
  const [showfirst, setShowfirst] = useState(true); // current first Step
  const [showSecond, seThowSecond] = useState(false); // current second (Password field)
  const [showThird, setShowthird] = useState(false); // current Thirs step( OTP field)
  {
    /*Resend OTP code*/
  }
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const generateOtp = () => {
    setMinutes(1);
    setSeconds(59);
  };
  const resendOTP = () => {
    setMinutes(1);
    setSeconds(59);
  };
  return (
    <>
      <Tabs
        defaultActiveKey="home"
        id="uncontrolled-tab-example"
        className="login-tab"
        activeKey={key}
        onSelect={(k) => dispatch(modalKey(k))}
      >
        <Tab eventKey="home">
          <LoginModal setShow={setShow} />
        </Tab>
        <Tab eventKey="profile" className="popClr">
          {showfirst && (
            <SignVerification
              setStep={setStep}
              setShowfirst={setShowfirst}
              seThowSecond={seThowSecond}
            />
          )}{" "}
          {/*First step */}
          {showSecond && (
            <ThirdStep
              seThowSecond={seThowSecond}
              showThird={showThird}
              setShowthird={setShowthird}
              generateOtp={generateOtp}
            />
          )}
          {/*second step */}
          {showThird && (
            <SecondStep
              setShowthird={setShowthird}
              showThird={showThird}
              resendOTP={resendOTP}
              minutes={minutes}
              seconds={seconds}
            />
          )}
          {/*Third step */}
        </Tab>
      </Tabs> 
    </>
  );
};
export default ModalPanel;
