import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { Modal } from "react-bootstrap-v5";
import { useDispatch, useSelector } from "react-redux";
import {  toast } from "react-toastify";
import DeactivateProfileAlert from "./DeactivateProfileAlert";
import { showDeactivateAlert } from "../../redux/counterSlice";
import { useNavigate } from "react-router-dom";

const DeactivateProfile = ({ deactivate, setDeactivate }) => {
  const [password, setPassword] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const dispatch = useDispatch()

  const initialValues = {
    password: "",
  };
  const {userDetails} = useSelector((state)=> state.userDetails)
  const [details, setDetails] = useState(initialValues);
  const [formError, setFormError] = useState({ initialValues });
  const navigate = useNavigate()
  const handleClose = () => setDeactivate(false);
  const onBlurHandler = (event) => {
    const fieldName = event.target.name;
    const fieldValue = details[fieldName];
    let err = { ...formError };
    if (fieldValue === "") {
      err[fieldName] = "Required!";
    } else {
      delete err[fieldName];
    }
    setFormError({ ...err });
  };

  const validateForm = () => {
    let err = {};
    if (details.password === "") {
      err.password = "Required!";
    }
    setFormError({ ...err });
    return Object.keys(err).length < 1;
  };

  const handleChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    let err = { ...formError };
    delete err[fieldName];
    setFormError({ ...err });
    if (fieldName === "password") {
      if (fieldValue.length > 16) {
        return;
      }
    }
    setDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const deactivateProfileRequest =  async()=>{
    const payload = {
     phoneCode: userDetails?.phoneCode,
     phone: userDetails?.phone,
     password: details.password
    };
    const API_URL =  `${process.env.REACT_APP_BASE_URL}/user/active-deactive-user`
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "post",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: payload,
      
    };
    try {
      const response = await axios(config);
      if(userDetails?.isActive=== true){
        toast.success("Profle Deactivated");
        Cookies.remove("accessToken");
        window.location.reload();
      }
      else if(userDetails?.isActive=== false){
        toast.success("Profle Activated"); 
        setTimeout(()=>{
          navigate("/")
          window.location.reload();
        },400)
      }
       
      
    }catch (error) {
      const {response} = error
      if(response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
        Cookies.remove("accessToken");
      }
      let message = response?.data?.errors[0]?.description.split("_").join(" ")
      toast.error(message)
    }

  }
 
  const handleSubmit = (e) => {
    deactivateProfileRequest()
  }

  const handleShowAlert = () => {

    const valid = validateForm();
    if (valid && userDetails?.isActive === true) {
      dispatch(showDeactivateAlert(true));
    }
    else if(valid && userDetails?.isActive === false){
      deactivateProfileRequest(userDetails?.isActive);
    }
  };
  return (
    <>
      <Modal
        show={deactivate}
        onHide={handleClose}
        size="xl"
        className="login-modal modalClosebtn customZindex"
      >
        <Modal.Header closeButton className="btn-close-white"></Modal.Header>
     
        <Modal.Body className="customFlexClass">
          <div className="row  m-0">
            <div className="col-12">
              <div className="row m-0">
                <div className="col-md-12 col-lg-12 d-flex justify-content-start align-items-center mx-auto">
                  <div className="heading-text mx-auto">
                    <div className="d-flex justify-content-center align-items-center mb-3">
                      <div className="titleWithIcon">
                      {userDetails?.isActive === true ?<i className="fa-solid fa-user-slash transactionIcon"></i>: <i className="fa-solid fa-user transactionIcon"></i> }
                      </div>
                      <p className=" section-heading customPopupsHeading transactionTitle">
                      {userDetails?.isActive === true ?  "Deactivate Profile" : "Activate Profile"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <form >
                <div className="user-input mt-2">
                  <input
                    className="form-control user-input"
                    type={`${password === true ? "text" : "password"}`}
                    name="password"
                    placeholder="Password"
                    aria-label="Operator ID"
                    value={details.password}
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={onBlurHandler}
                  />
                  <i
                    className={`${
                      password ? "bi bi-eye" : "bi bi-eye-slash"
                    }  toggle-password`}
                    onClick={() => setPassword(!password)}
                  />
                </div>

                <span className="errorMsg">{formError.password}</span>
                <div className="d-grid gap-2  signin-btn mb-5">
                  <button className="btn btn-sign" type="button" onClick={handleShowAlert}>
                  {userDetails?.isActive === true ?  "Deactivate" : "Activate"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <DeactivateProfileAlert  handleSubmit={handleSubmit}/>
    </>
  );
};

export default DeactivateProfile;
