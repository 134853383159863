import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    notificationCounts:0

}
 const notificationsCountSlice = createSlice({
  name: 'notificationsCount',
  initialState,
  reducers: {
   setNotificationsCount: (state, action)=>{
    state.notificationCounts = action.payload
   }

  },
})

export const { setNotificationsCount} = notificationsCountSlice.actions

export default notificationsCountSlice.reducer