import React, { useState } from "react";
import { Modal } from "react-bootstrap-v5";
import { useSelector, useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { showForgotUsernameModal } from "../../redux/counterSlice";
import axios from "axios";
import { toast } from "react-toastify";
const ForgotUsernamePop = () => {
  const dispatch = useDispatch();

  const { isShowForgotUsernameModal } = useSelector((state) => state.counter);
  const initialValues = {
    phoneNumber: "",
    isAgree: false
  };
  const [phone, setPhone] = useState(""); 
  const [details, setDetails]  = useState(initialValues)
  const [phoneValid,setPhoneValid] = useState(false) 
  const [formError, setFormError] = useState({ initialValues });
  const validateForm = () => {
    let err = {};
     
    if (details.phoneNumber === "") {
      err.phoneNumber = "Required!";
    } else if (!phoneValid) {
      
      err.phoneNumber = "Invalid Phone number";
    }
    if (details.isAgree === false) {
      err.isAgree = "Required!";
    }
   
    setFormError({ ...err });
    return Object.keys(err).length < 1;
  };

  const handlePhoneChange = (value,country) =>{
    setDetails({...details,phoneNumber: value})
    setFormError({...formError, phoneNumber:""})
    let validNum =  value.length - country.dialCode.length >= 10
    setPhoneValid(validNum)
    
  }
  const handleOnchange = (e) => {
    
    const fieldName = e.target.name;
    let err = { ...formError };
    delete err[fieldName];
    setFormError({ ...err });
      setDetails((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    }));
  };

  const getUsername = async()=>{   
    let phoneCode =details?.phoneNumber?.slice(0, phone.length-10);
    let phoneNum = details?.phoneNumber?.slice(details?.phoneNumber.length-10, details?.phoneNumber.length) 
    const payload = {
      phoneCode:phoneCode,
      phone:phoneNum
    };

    const API_URL = `${process.env.REACT_APP_BASE_URL}/user/forgot-username`; 
    const config = {
      method: "post",
      url: API_URL,
      headers: { 
        type: "text",
      },
      data: payload,
    };
    try {
      const response = await axios(config); 
      toast.success("Username Sent");
    
    } catch (error) {
      const { response } = error;

      let message = response?.data?.errors[0]?.description.split("_");
      let updatedMessage = message?.join(" ");
      toast.error(updatedMessage);
     
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
   let valid = validateForm()
   if(valid){
    getUsername()
   }
  };
 
  
  const handleClose = () => {
    dispatch(showForgotUsernameModal(false));
    setDetails(initialValues)
    setFormError(initialValues)
  };
  return (
    <>
      <Modal
        show={isShowForgotUsernameModal}
        onHide={handleClose}
        size="xl"
        className="login-modal modalClosebtn  d-flex align-items-center justify-content-center"
      >
        <Modal.Header closeButton className="btn-close-white"></Modal.Header>
        <Modal.Body className="customFlexClass">
          <div className="row  m-0" style={{ width: "100%" }}>
            <div className="col-md-6 col-sm-12 mx-auto pt-4 pb-4">
              <div className="row  m-0" style={{ width: "100%" }}>
                <div className="col-sm-12 mx-auto">
                <form onSubmit={handleSubmit}>
                <div className="row m-0">
                  <div className="col-md-12 col-lg-12 d-flex justify-content-start align-items-center mx-auto">
                    <div className="heading-text mx-auto mt-4 mb-4">
                      <div className="d-flex justify-content-center align-items-center mb-3">
                        <p className=" section-heading customPopupsHeading transactionTitle">
                          {" "}
                          <span>
                            {" "}
                            <i className="fa-solid fa-pen-to-square transactionIcon me-3"></i>
                          </span>
                          Forgot Username ?
                        </p>
                      </div>
                    </div>
                    <p></p>
                  </div>
                </div>{" "}
                <div className="row">
                  <div className="col-md-12 mt-2 mb-3">
                    <div className="user-input ">
                      <p className="forgotPasswordDes">
                        To recover your username, please enter the Phone Number associated
                        with your account.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-2 mb-3">
                    <div className="user-input">
                      <PhoneInput
                        country={"us"}
                        className="newPhoneInput"
                        name="phoneNumber"
                        type="text"
                        placeholder="Phone Number"
                        aria-label="first-name"
                        autoComplete="off"
                        value={phone}
                        onChange={handlePhoneChange}
                        maxLength={20}
                      />
                      
                    </div>
                    <span className="errorMsg">{formError.phoneNumber}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-2 mb-3">
                    <div className="form-check mb-0">
                      <div className="col">
                        <div className="rememberme">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="checkbox1"
                            name="isAgree"
                            onChange={handleOnchange}
                          />
                          <label
                            className="form-check-label label-text"
                            htmlFor="checkbox"
                          >
                            I agree to receive Username by text message.
                          </label>
                        </div>
                        </div>
                        </div>
                        <span className="errorMsg">{formError.isAgree}</span>
                  </div>
                </div>
                <div className="d-grid gap-2 signin-btn">
                  <button className="btn btn-sign" type="submit">
                    Get Username
                  </button>
                </div>
              </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ForgotUsernamePop;
