import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap-v5";

import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { showForgotPasswordModal } from "../../redux/counterSlice";
import { toast } from "react-toastify";
import axios from "axios";

const ForgotPassword = () => {
  let [newaccessToken, setNewccessToken] = useState();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [password, setPassword] = useState(false);
  const [cpassword, setCpassword] = useState(false);

  const [step, setStep] = useState(0);
  const [phone, setPhone] = useState("");
  const { isShowForgotPasswordModal } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
 

  const firstStep = {
    number: "",
    codeConfirmation: false,
  };

  const secondStep = {
    verificationCode: "",
  };
  const thirdStep = {
    password: "",
    confirmPassword: "",
    
  };

  /****handle close*** */
  const handleClose = () => {
    dispatch(showForgotPasswordModal(false));
    setFirstStepError(firstStep)
    setFirstStepDetails(firstStep)
    setPhone("")
    setStep(0);
  };


  /*** Error Messages */

  const [firstStepError, setFirstStepError] = useState();
  const [secondStepError, setSecondStepError] = useState();
  const [thirdStepError, setThridStepError] = useState();

  /***** states to store inputs  */
  const [firstStepDetails, setFirstStepDetails] = useState(firstStep);
  const [secondStepDetails, setSecondStepDetails] = useState(secondStep);
  const [thirdStepDetails, setThirdStepDetails] = useState(thirdStep);

  /**** Forgot Password API   ****** */

  const resetCurrentPassword = async () => {
    const payload = {
      phone: firstStepDetails.number.slice(firstStepDetails.number.length - 10),
    };
    const API_URL = `${process.env.REACT_APP_BASE_URL}/user/forgot-password`;
    const config = {
      method: "post",
      url: API_URL,
      headers: {
        type: "text",
      },
      data: payload,
    };
    try {
      const response = await axios(config);
      if (response?.status === 200) {
        toast.success("OTP Sent");
        setStep(1);
        generateOtp();
      }
    } catch (error) {
      const { response } = error;
      let message = response?.data?.errors[0]?.description.split("_").join(" ");
      toast.error(message);
    }
  };

  const verifyOtpResetPassword = async () => {
    const payload = {
      phone: firstStepDetails.number.slice(firstStepDetails.number.length - 10),
      code: secondStepDetails.verificationCode,
    };
    const API_URL = `${process.env.REACT_APP_BASE_URL}/user/verify-otp-reset-password`;
    const config = {
      method: "post",
      url: API_URL,
      headers: {
        type: "text",
      },
      data: payload,
    };
    try {
      const response = await axios(config);
      if (response?.status === 200) {
        setNewccessToken(response?.data?.data?.accessToken);
        toast.success("OTP Verified");
        setStep(2);
      }
    } catch (error) {
      const { response } = error;
      let message = response?.data?.errors[0]?.description.split("_").join(" ");
      toast.error(message);
    }
  };

  const addNewPassword = async () => {
    const payload = {
      password: thirdStepDetails.password,
    };
    const API_URL = `${process.env.REACT_APP_BASE_URL}/user/change-forgot-password`;
    const config = {
      method: "post",
      url: API_URL,
      headers: {
        Authorization: newaccessToken,
        type: "text",
      },
      data: payload,
    };
    try {
      const response = await axios(config);
      if (response.status === 200) {
        toast.success("Password Updated Successfully");

        dispatch(showForgotPasswordModal(false));
        setFirstStepDetails(firstStep);
        secondStepDetails(secondStep);
        setThirdStepDetails(thirdStep);
      }
    } catch (error) {
      const { response } = error;
      let message = response?.data?.errors[0]?.description.split("_").join(" ");
      toast.error(message);
    }
  };

  /********First step validation ********* */

  const handleFirststepOnchange = (e) => {
    const fieldName = e.target.name;
    let err = { ...firstStepError };
    delete err["number"];
    delete err[fieldName];
    setFirstStepError({ ...err });
    if (fieldName === "number" && isNaN(e.target.value)) {
      return;
    }
    if (fieldName === "username" && e.target.value.includes("@")) {
      return;
    }
    setFirstStepDetails((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    }));
  };

  const validateForm = () => {
    let err = {};
    if (firstStepDetails.number === "") {
      err.number = "Required!";
    } else if (firstStepDetails.number.length < 10) {
      err.number = "Invalid phone number";
    }
    if (firstStepDetails.codeConfirmation === false) {
      err.codeConfirmation = "Required!";
    }
    setFirstStepError({ ...err });
    return Object.keys(err).length < 1;
  };

  const handleSubmitFirstStep = () => {
    firstStepDetails["number"] = phone;
    const valid = validateForm();
    if (valid) {
      resetCurrentPassword();
    }
  };

  /*********second step ********* */
  const onBlurHandlerSecondstep = (event) => {
    let fieldName = event.target.name;
    let err = { ...secondStepError };
    if (secondStepDetails.verificationCode === "") {
      err["verificationCode"] = "Required!";
    } else {
      delete err[fieldName];
    }

    setSecondStepError({ ...err });
  };

  const handleSecondstepOnchange = (e) => {
    const fieldName = e.target.name;
    let err = { ...secondStepError };
    delete err[fieldName];
    setSecondStepError({ ...err });
    if (fieldName === "verificationCode" && isNaN(e.target.value)) {
      return false;
    }

    setSecondStepDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const validateFormSecondStep = () => {
    let err = {};
    if (secondStepDetails.verificationCode === "") {
      err.verificationCode = "Required!";
    }
    if (secondStepDetails.verificationCode.length < 4) {
      err.verificationCode = "Invalid OTP!";
    }
    setSecondStepError({ ...err });
    return Object.keys(err).length < 1;
  };

  const handleSubmitSecondStep = () => {
    validateFormSecondStep();
    const valid = validateFormSecondStep();
    if (valid) {
      verifyOtpResetPassword();
    }
  };

  /****** Third Step ***** */
  const onBlurHandlerThirdstep = (event) => {
    const fieldName = event.target.name;
    const fieldValue = thirdStepDetails[fieldName];
    let err = { ...thirdStepError };

    if (fieldName === "password" || fieldName === "confirmPassword") {
      if (fieldValue.length < 8) {
        err[fieldName] =
          "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 special character, and be at least 8 characters long.";
      }
    }
    if (fieldValue === "") {
      err[fieldName] = "Required!";
    } else {
      delete err[fieldName];
    }
    setThridStepError({ ...err });
  };

  const handleThirdstepOnchange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    let err = { ...thirdStepError };
    delete err[fieldName];

    if (fieldName === "password" || fieldName === "confirmPassword") {
      if (fieldValue.length > 16) {
        return;
      }
    }

    setThirdStepDetails((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    }));
    setThridStepError({ ...err });
  };
  const validateFormThirdStep = () => {
    let err = {};
    if (thirdStepDetails.password === "") {
      err.password = "Required!";
    }
    if (thirdStepDetails.confirmPassword === "") {
      err.confirmPassword = "Required!";
    } else {
      if (thirdStepDetails.password !== thirdStepDetails.confirmPassword) {
        err.confirmPassword = "Passwords do not match!";
      } else if (!isValidPassword(thirdStepDetails.password)) {
        err.confirmPassword =
          "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 special character, and be at least 8 characters long.";
      }
    }
    setThridStepError({ ...err });
    return Object.keys(err).length < 1;
  };
  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
    return passwordRegex.test(password);
  };

  const handleSubmitThirdStep = () => {
    const valid = validateFormThirdStep();
    if (valid) {
      addNewPassword();
    }
  };

  /******* Functions for steps****** */

  const handlePhoneNumberInput = () => {
    return (
      <div>
        <div className="row m-0">
          <div className="col-md-12 col-lg-12 d-flex justify-content-start align-items-center mx-auto">
            <div className="heading-text mx-auto">
              <div className="d-flex justify-content-center align-items-center mb-3">
                <p className=" section-heading customPopupsHeading transactionTitle">
                  {" "}
                  <span>
                    {" "}
                    <i className="fa-solid fa-lock transactionIcon me-3"></i>
                  </span>
                  Forgot Password ?
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-2 mb-3">
            <div className="user-input ">
              <p className="forgotPasswordDes">
                To reset your password, please enter the Phone Number associated
                with your account.
              </p>
              <div className="password-input phoneNumberInput flagdropDwon text-dark">
                <PhoneInput
                  country={"us"}
                  name="number"
                  className="newPhoneInput"
                  value={phone}
                  onChange={(value) => setPhone(value)}
                />
              </div>
              <span className="errorMsg">{firstStepError?.number}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-2 mb-3">
            <div className="form-check mb-0">
              <div className="col">
                <div className="rememberme">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="checkbox1"
                    checked={firstStepDetails.codeConfirmation}
                    onChange={handleFirststepOnchange}
                    name="codeConfirmation"
                  />
                  <label
                    className="form-check-label label-text"
                    htmlFor="checkbox"
                  >
                    I agree to receive a Verification Code by text message.
                  </label>
                </div>
              </div>
            </div>
            <span className="errorMsg">{firstStepError?.codeConfirmation}</span>
          </div>
        </div>

        <div className="d-grid gap-2 signin-btn">
          <button
            className="btn btn-sign"
            type="submit"
            onClick={handleSubmitFirstStep}
          >
            Get Code
          </button>
        </div>
      </div>
    );
  };

  const handleOtpInput = () => {
    return (
      <div>
        <div className="row m-0">
          <div className="col-md-12 col-lg-12 d-flex justify-content-start align-items-center mx-auto">
            <div className="heading-text mx-auto">
              <div className="d-flex justify-content-center align-items-center mb-3">
                <p className=" section-heading customPopupsHeading transactionTitle">
                  {" "}
                  <span className="me-3">
                    {" "}
                    <i className="fa-solid fa-lock transactionIcon"></i>
                  </span>
                  Forgot Password ?
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-2 mb-3">
            <div className="verification">
              <p className="forgotPasswordDes">
                To continue, please enter 4-digit verification code we sent to
                your phone number.
              </p>
            </div>
            <div className="password-input mt-2">
              <input
                className="form-control user-input mt-3"
                type="text"
                placeholder="Enter Verification Code"
                aria-label="verification"
                name="verificationCode"
                value={secondStepDetails.verificationCode}
                maxLength={4}
                onChange={handleSecondstepOnchange}
                onBlur={onBlurHandlerSecondstep}
              />
            </div>
            <span className="errorMsg">
              {secondStepError?.verificationCode}
            </span>
          </div>
        </div>
        <div className="d-grid gap-2 signin-btn">
          {seconds > 0 || minutes > 0 ? (
            <p className="text-white">
              Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </p>
          ) : (
            <p className="text-white">Didn't recieve code?</p>
          )}
        </div>
        <div className="rememberme mt-3">
          <p className=" text-white confirm-text">
            Did not receive a text message ?{" "}
            <a className="text-decoration-none">
              <button
                className="brand-name sendAgainBtn"
                disabled={seconds > 0 || minutes > 0}
                onClick={() => {
                  resendOTP();
                  resetCurrentPassword();
                }}
              >
                Send again{" "}
              </button>
            </a>{" "}
            or{" "}
            <a href="#" className="text-decoration-none">
              <span className="brand-name">request a voice call. </span>{" "}
            </a>
          </p>
        </div>
        <div className="d-grid gap-2 signin-btn">
          <button
            className="btn btn-sign"
            type="submit"
            onClick={handleSubmitSecondStep}
          >
            Verify
          </button>
        </div>
      </div>
    );
  };

  const handleSetPassword = () => {
    return (
      <div>
        <div className="row m-0">
          <div className="col-md-12 col-lg-12 d-flex justify-content-start align-items-center mx-auto">
            <div className="heading-text mx-auto">
              <div className="d-flex justify-content-center align-items-center mb-3">
                <p className=" section-heading customPopupsHeading transactionTitle">
                  {" "}
                  <span>
                    <i className="fa-solid fa-lock transactionIcon me-3"></i>
                  </span>
                  Set New Password ?
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-2 mb-3">
            <div className="user-input ">
              <label
                htmlFor="inputState"
                className="form-label text-white mb-2 customPopUpLabel"
              >
                New Password*
              </label>
              <input
                className="form-control user-input"
                type={password ? "text" : "password"}
                name="password"
                placeholder="New Password"
                maxLength={20}
                onChange={handleThirdstepOnchange}
                onBlur={onBlurHandlerThirdstep}
              />
              <i
                className={`${
                  password ? "bi bi-eye" : "bi bi-eye-slash"
                }  toggle-password`}
                onClick={() => setPassword(!password)}
              />
            </div>
            <span className="errorMsg">{thirdStepError?.password}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-3 mt-2">
            <div className="user-input">
              <label
                htmlFor="inputState"
                className="form-label text-white mb-2 customPopUpLabel"
              >
                Confirm New Password*
              </label>
              <input
                className="form-control user-input"
                name="confirmPassword"
                type={cpassword ? "text" : "password"}
                maxLength={20}
                placeholder="Confirm New Password"
                aria-label="first-name"
                autoComplete="off"
                onChange={handleThirdstepOnchange}
                onBlur={onBlurHandlerThirdstep}
              />
              <i
                className={`${
                  cpassword ? "bi bi-eye" : "bi bi-eye-slash"
                }  toggle-password`}
                onClick={() => setCpassword(!cpassword)}
              />
            </div>
            <span className="errorMsg">{thirdStepError?.confirmPassword}</span>
          </div>
        </div>
        <div className="d-grid gap-2 signin-btn">
          <button className="btn btn-sign" onClick={handleSubmitThirdStep}>
            Update
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const generateOtp = () => {
    setMinutes(1);
    setSeconds(59);
  };
  const resendOTP = () => {
    setMinutes(1);
    setSeconds(59);
  };

  return (
    <>
      <Modal
        show={isShowForgotPasswordModal}
        onHide={handleClose}
        size="xl"
        className="login-modal modalClosebtn  d-flex align-items-center justify-content-center"
      >
        <Modal.Header closeButton className="btn-close-white"></Modal.Header>

        <Modal.Body className="customFlexClass">
          <div className="row  m-0" style={{ width: "100%" }}>
            <div className="col-md-6 col-sm-12 mx-auto pt-4 pb-4">
              <div className="row  m-0" style={{ width: "100%" }}>
                <div className="col-sm-12 mx-auto">
                  <div className="resetFields">
                    {step === 0 && handlePhoneNumberInput()}
                    {step === 1 && handleOtpInput()}

                    {step === 2 && handleSetPassword()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ForgotPassword;
