import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {showDeactivateAlert} from "../../redux/counterSlice"

const DeactivateProfileAlert = ({handleSubmit}) => {
const {isShowDeactivateAlert} = useSelector((state)=> state.counter)
const dispatch = useDispatch()


 

  return (
    <>
    <div
        className={`modal fade ${isShowDeactivateAlert ? "show d-block modal-hide" : ""}`}
        id="operatorModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"

      >
        <div className="modal-dialog modal-fullscreen-lg-down modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content modalCustomHeight">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() =>  dispatch(showDeactivateAlert(false))}
              />
            </div>
            <div className="modal-body d-flex mx-auto align-items-center text-white p-0">
              <div className="row m-0">
                <div className="col-12">
                  <div className="deleteOperator termsConditon text-center">
                    {" "}
                    If you deactivate the profile, you may lose all credits and operators.
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-12 text-center">
                    <div className="deleteOperator termsConditon p-4">
                      <span className="deleteOprAlert">Important!</span> All
                      remaing credits under all operators will be lost
                    </div>
                  </div>
                  <div className="col-12 text-center">
                  <div className="p-4">
                  <button
                  className="btn btn-secondary me-3"
                  onClick={() => dispatch(showDeactivateAlert(false))}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-danger" onClick={()=>{handleSubmit(); dispatch(showDeactivateAlert(false)) }}
                >
                  Delete
                </button>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeactivateProfileAlert;
