import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { io } from "socket.io-client";

export const getMainWalletDetails = createAsyncThunk(
  "mainWallet",
  async (requestData, { rejectWithValue }) => {
    const API_URL = `${process.env.REACT_APP_BASE_URL}/user/get-wallets`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "get",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: requestData,
    };

    try {
      const response = await axios(config);
      return response?.data?.data?.wallets;
    } catch (error) {
      const response = error;
      if (
        response?.data?.errors[0]?.description ===
        "Either access token not passed or it is expired"
      ) {
        Cookies.remove("accessToken");
      }
      return rejectWithValue(error);
    }
  }
);

const mainWalletSlice = createSlice({
  name: "getUserDetails",
  initialState: {
    mainWalletDetails: {
      amount: 0,
      gameCredits: 0,
      gameWallets: [],
      nonCashAmount:0,
      id: null,
      operator_id: null,
    },
    loading: false,
    error: null,
  },
  reducers: {
    updateGameCradit(state, action) {
      const copy = state;  
      copy.mainWalletDetails.gameCredits = action.payload;
    
      return copy;
    },
    updateBonusCradit(state, action) {
      const copy = state;  
      copy.mainWalletDetails.nonCashAmount = action.payload;
    
      return copy;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMainWalletDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMainWalletDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.mainWalletDetails = action.payload;
      })
      .addCase(getMainWalletDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { updateGameCradit,updateBonusCradit } = mainWalletSlice.actions;

export default mainWalletSlice.reducer;
