import React, { useState } from "react";
import RedeemTermConditonPopup from "./RedeemTermConditonPopup";
import { Modal } from "react-bootstrap-v5";

const AddCreditPopup = ({ showAddCredit,setAddShowCredit ,handleLaunchPrizeGame}) => {
  const [isShow, setIsShow] = useState(false);
  const [availabeCredit, setAvailableCredit] = useState(0);

  const [selectedAmount, setSelectedAmount] = useState(0);
  const handleClose = () => {
   
      setAddShowCredit(false)
      setSelectedAmount(0)
    }

  const handleSelectAmount = (value) => {
    const temp_sum = value + selectedAmount;
    if (temp_sum <= availabeCredit) {
      setSelectedAmount((prev) => prev + value);
    } else {
      alert("Select amount can't be more than available credit");
    }
  };

  const handleConfirm = ()=>{
    setAddShowCredit(false)
    handleLaunchPrizeGame()
  }

  return (
    <>
      <Modal
        show={showAddCredit}
        onHide={handleClose}
        size="xl"
        className="login-modal modalClosebtn d-flex align-items-center"
      >
        <Modal.Header closeButton className="btn-close-white"></Modal.Header>
        <Modal.Body className="customFlexClass addCreditPopUp row  m-0">
          <div className="col-md-7 mx-auto ">
            <div className="creditTitle mb-3">
              {" "}
              <span className="creditIcon">
                <i className="fa-regular fa-money-bill-1"></i>
              </span>{" "}
              Add Credit
            </div>

            <div className="availableCredits mb-5 text-center">
              Available Credits: <span>{availabeCredit.toFixed(2)}</span>
            </div>

            <div className="selectAmount w-100 mb-5">
              {selectedAmount === 0
                ? "Select Amount"
                : selectedAmount.toFixed(2)}
            </div>
            <div className="amountButton mb-5">
              <button
                className="amountBtn"
                onClick={() => handleSelectAmount(1)}
              >
                +1.00
              </button>
              <button
                className="amountBtn"
                onClick={() => handleSelectAmount(5)}
              >
                +5.00
              </button>
              <button
                className="amountBtn"
                onClick={() => handleSelectAmount(10)}
              >
                +10.00
              </button>
              <button
                className="amountBtn"
                onClick={() => handleSelectAmount(20)}
              >
                +20.00
              </button>
              <button
                className="amountBtn"
                onClick={() => handleSelectAmount(50)}
              >
                +50.00
              </button>
              <button
                className="amountBtn allBtn"
                onClick={() => handleSelectAmount(availabeCredit)}
              >
                All
              </button>
            </div>
            <div className="totalAmount mb-3">
              Total: <span>{selectedAmount.toFixed(2)}</span>
            </div>
            <div className=" signin-btn text-center">
              <button className="btn btn-sign w-100" onClick={handleConfirm}>Confirm</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCreditPopup;
